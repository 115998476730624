import React from "react";
import { ContractTable } from "views/organisms/Masters/Contract";

/**
 * 契約の情報を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return <ContractTable onSelected={props.onTransite} {...props} />;
};
