import { format } from "date-fns";

const now = new Date();
export const sampleData = [
  {
    id: 1,
    createdAt: now.toISOString(),
    scheduleDate: format(now.setDate(now.getDate() + 1), "yyyy-MM-dd"),
    wasteCollectionWorkplaceName: "ABC廃棄物管理株式会社",
    wasteGeneratorCompanyName: "東京工場1",
    scheduleTimeRangeStart: "09:00",
    scheduleTimeRangeEnd: "12:00",
  },
  {
    id: 2,
    createdAt: now.toISOString(),
    scheduleDate: format(now.setDate(now.getDate() + 1), "yyyy-MM-dd"),
    wasteCollectionWorkplaceName: "XYZリサイクル株式会社",
    wasteGeneratorCompanyName: "大阪センター",
    scheduleTimeRangeStart: "13:00",
    scheduleTimeRangeEnd: "16:00",
  },
  {
    id: 3,
    createdAt: now.toISOString(),
    scheduleDate: format(now.setDate(now.getDate() + 1), "yyyy-MM-dd"),
    wasteCollectionWorkplaceName: "エコグリーン株式会社",
    wasteGeneratorCompanyName: "名古屋オフィス",
    scheduleTimeRangeStart: "09:00",
    scheduleTimeRangeEnd: "12:00",
  },
  {
    id: 4,
    createdAt: now.toISOString(),
    scheduleDate: format(now.setDate(now.getDate() + 1), "yyyy-MM-dd"),
    wasteCollectionWorkplaceName: "アーバン廃棄ソリューションズ",
    wasteGeneratorCompanyName: "福岡支店",
    scheduleTimeRangeStart: "13:00",
    scheduleTimeRangeEnd: "16:00",
  },
  {
    id: 5,
    createdAt: now.toISOString(),
    scheduleDate: format(now.setDate(now.getDate() + 1), "yyyy-MM-dd"),
    wasteCollectionWorkplaceName: "グリーンアース株式会社",
    wasteGeneratorCompanyName: "札幌施設",
    scheduleTimeRangeStart: "09:00",
    scheduleTimeRangeEnd: "12:00",
  },
];
