import { RenderButtonToolBar } from "utils/XgridExpand";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { ContractForm } from "views/organisms/Masters/Partner/ContractForm";

/**
 * 契約を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <XGridComponent
        onChangeSelection={props.onChangeSelection}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        actionButton={
          <RenderButtonToolBar
            label="新しい契約を追加"
            onClick={props.openAddNewContractDialog}
          />
        }
        {...props.xGridParams}
        loading={props.loading}
      />
      <FullScreenDialog
        open={props.open}
        onClose={props.onCloseDialog}
        title={`新しい契約の追加`}
        textConfirm="登録"
        formId="AddNewContractForm"
        isSubmit={props.isSubmitting}
        disabled={props.isSubmitting}
      >
        <ContractForm
          id={"AddNewContractForm"}
          value={props.formValue}
          onSubmit={props.onSubmit}
        />
      </FullScreenDialog>
    </>
  );
};
