import {
  Drawer,
  Divider,
  List,
  Box,
  Switch,
  Typography,
  ListSubheader,
  Paper,
  Skeleton,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import { widgetIds } from "utils/DashboardWidgetConstant";

const iconTypes = {
  alert: <NotificationsActiveOutlinedIcon />,
  task: <TaskAltOutlinedIcon />,
  chart: <BarChartOutlinedIcon />,
  timeline: <TimelineOutlinedIcon />,
  list: <FormatListBulletedOutlinedIcon />,
  table: <DvrOutlinedIcon />,
};

const icons = {
  [widgetIds.COLLECTION_VOLUME_CHART]: iconTypes.chart,
  [widgetIds.TODAYS_COLLECTION_PROGRESS]: iconTypes.task,
  [widgetIds.DRIVERS_TIMELINE]: iconTypes.timeline,
  [widgetIds.PERMIT_EXPIRATION_ALERTS]: iconTypes.alert,
  [widgetIds.AGREEMENT_EXPIRATION_ALERTS]: iconTypes.alert,
  [widgetIds.MISSED_VEHICLE_ALERTS]: iconTypes.alert,
  [widgetIds.UNCOLLECTED_ALERTS]: iconTypes.alert,
  [widgetIds.CONTAINER_INSTALLATION_ALERTS]: iconTypes.alert,
  [widgetIds.COORDINATE_NOT_REGISTER_ALERTS]: iconTypes.alert,
  [widgetIds.VEHICLE_REMIND_ALERTS]: iconTypes.alert,
  [widgetIds.ALL_COURSE_STATUS]: iconTypes.list,
  [widgetIds.RECENT_CONTRACT_START_LIST]: iconTypes.table,
  [widgetIds.RECENT_ADDED_ACCOUNTS]: iconTypes.table,
  [widgetIds.RECENT_ADDED_SPOT_COLLECTIONS]: iconTypes.table,
};

/**
 * InfoTooltipコンポーネント
 * 
 * このコンポーネントは、情報ツールチップを表示します。
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.content - ツールチップに表示するコンテンツ
 * 
 * @returns {JSX.Element} ツールチップを含むReactコンポーネント
 */
const InfoTooltip = ({ content }) => {
  const tooltipContent = content?.split("\n");
  return (
    <Tooltip
      title={
        <Box style={{ maxWidth: "none", whiteSpace: "normal" }}>
          <Typography fontSize={12}>ウィジェット情報</Typography>
          <Divider />
          <Box component="ul">
            {tooltipContent?.map((item, index) => (
              <Box component="li" key={index} fontSize={12}>
                {item}
              </Box>
            ))}
          </Box>
        </Box>
      }
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      placement="left"
    >
      <InfoOutlinedIcon
        fontSize="15"
        sx={{
          color: "gray",
          ":hover": {
            color: "primary.main",
            cursor: "pointer",
          },
        }}
      />
    </Tooltip>
  );
};

/**
 * Presententerコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.open - ドロワーが開いているかどうか
 * @param {Function} props.handleDrawerClose - ドロワーを閉じる関数
 * @param {Object} props.userPreferences - ユーザーの設定
 * @param {Function} props.onChangeWidgetStatus - ウィジェットのステータスを変更する関数
 * @param {number} props.DRAWERWIDTH - ドロワーの幅
 * @param {Array} props.widgets - ウィジェットの配列
 * @param {Function} props.handleWidgetSelect - ウィジェット選択を処理する関数
 */
export const Presententer = ({
  open,
  handleDrawerClose,
  userPreferences,
  onChangeWidgetStatus,
  DRAWERWIDTH,
  widgets,
  handleWidgetSelect,
}) => {
  const checkedStatus = (widgetId) => {
    const widget = userPreferences.items.find(
      (widget) => widget.id === widgetId
    );
    if (!widget) return false;
    return true;
  };

  return (
    <Drawer
      sx={{
        width: DRAWERWIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWERWIDTH,
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <Box
        sx={{
          mt: "64px",
        }}
      />
      <Box
        sx={{
          height: 40,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid lightgray",
          px: 1,
        }}
      >
        <Typography fontWeight={800} fontSize={14}>
          ウィジェットの選択
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon fontSize="15" />
        </IconButton>
      </Box>
      <Divider />
      {widgets.loading && <Skeleton width="100%" height="100%" />}
      {!widgets.loading && (
        <List
          subheader={
            <ListSubheader>
              <Typography fontSize={12} color="text.secondary">
                キャンバスに追加したいウィジェットを選択してください。表示を非表示にしたいウィジェットは、チェックを外してください。
              </Typography>
            </ListSubheader>
          }
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: "100%",
          }}
        >
          {Object.values(widgets.items).map((widget) => {
            return (
              <Paper
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  m: 1,
                  display: "flex",
                  alignItems: "center",
                  p: 1,
                  cursor: "pointer",
                }}
                key={widget.id}
              >
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleWidgetSelect(widget.id)}>
                    <ListItemIcon>
                      <Box
                        sx={{
                          bgcolor: "#E2CDEC",
                          width: 35,
                          height: 35,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                        }}
                      >
                        {icons[widget.id]}
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        "& span": {
                          fontSize: 13,
                          fontWeight: 600,
                        },
                      }}
                      primary={widget.name}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "end",
                      }}
                    >
                      <InfoTooltip content={widget.details} />
                      <Switch
                        edge="end"
                        size="small"
                        checked={checkedStatus(widget.id)}
                        onChange={(event) => {
                          onChangeWidgetStatus(event.target.checked, widget.id);
                        }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              </Paper>
            );
          })}
        </List>
      )}
    </Drawer>
  );
};
