import Fab from "@mui/material/Fab";
import SettingsIcon from "@mui/icons-material/Settings";

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {function} props.onClick - ボタンクリック時のコールバック関数。
 * @returns {JSX.Element} カスタマイズアイコンコンポーネント。
 */

export const Presententer = ({ onClick }) => {
  return (
    <Fab
      component="div"
      onClick={onClick}
      size="medium"
      variant="circular"
      color="primary"
      sx={{
        borderRadius: 0,
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "4px",
        top: "25%",
        position: "fixed",
        right: 10,
        zIndex: 100,
      }}
    >
      <SettingsIcon />
    </Fab>
  );
};
