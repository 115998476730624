import { Box, Typography } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { LoadingSkeleton } from "views/pages/Dashboard/Components";

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {boolean} [props.animate=false] - アラートボーダーをアニメーションするかどうか。
 * @param {import("@mui/material").AlertProps["variant"]} props.variant - アラートのバリアント。
 * @param {import("@mui/material").AlertProps["severity"]} [props.severity="warning"] - アラートの重大度。
 * @param {import("@mui/material").Theme} props.theme - The theme object.
 * @param {React.ReactNode} props.children - アラートの内容。"severity"]} [props.severity="warning"] - The severity of the alert.
 * @returns {JSX.Element} アニメーションされたアラートコンポーネント。
 */

export const Presententer = ({ total, title, onClick, loading }) => {
  if (loading) {
    return <LoadingSkeleton loading />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 2,
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        fontWeight={600}
        fontSize={16}
        color="text.secondary"
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <NotificationsActiveOutlinedIcon
          sx={{
            fontSize: 35,
            color: "text.secondary",
          }}
        />
        <Typography fontWeight={600} color="error" fontSize={25}>
          {total || 0}
          <Typography
            variant="h5"
            fontWeight={500}
            color="text.secondary"
            component="span"
          >
            件
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
