import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { signOut } from "aws-amplify/auth";
import CircularProgress from "@mui/material/CircularProgress";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CheckCircle, ErrorOutline } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { searchUserAccountsByOffset } from "api/graphql/queries";
import { transferOwnership } from "api/graphql/mutations";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { debugLog } from "utils/log";
import { LoadingButton } from "@mui/lab";

const ADMIN_USER_ROLE = "管理者";
const PROGRESS_STEP = {
  currentStep: 0,
  loginStep: 1,
  transferringStep: 2,
  errorStep: 3,
  successStep: 4,
};

const OwnerShipTransferDialog = ({ ownership }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [step, setStep] = useState(PROGRESS_STEP.currentStep);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const selectedUserId = queryParams.get("selectedUserId");
  const queryStep = queryParams.get("step");
  const isLoginSuccess = queryParams.get("success");

  const handleTransfer = () => {
    setOpen(true);
    API.graphql(
      graphqlOperation(transferOwnership, {
        input: {
          newOwnerUserId: selectedUserId,
        },
      })
    )
      .then(() => {
        setSubmitting(false);
        setStep(PROGRESS_STEP.successStep);
      })
      .catch(() => {
        setStep(PROGRESS_STEP.errorStep);
        debugLog(
          "get Error ownershipTransfer at path src/features/MembersMange/OwnerShipTransferDialog/index.js"
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (queryStep && selectedUserId && isLoginSuccess) {
      setStep(parseInt(queryStep));
      setSelectedUser({
        id: selectedUserId,
      });
      ownership.toggle(true);
      setOpen(true);
      setStep(PROGRESS_STEP.transferringStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStep, selectedUserId]);

  useEffect(() => {
    if (step === PROGRESS_STEP.transferringStep) {
      setSubmitting(true);
      handleTransfer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const updateQueryParam = () => {
    const queryParams = new URLSearchParams({
      selectedUserId: selectedUser.id,
      step: step,
      success: true,
    }).toString();
    // Update the URL with new query parameter without page reload
    navigate(`${location.pathname}?${queryParams}`, { replace: true });
  };

  const handleOpen = () => {
    setOpen(true);
    (async () => {
      setLoading(true);
      if (options.length === 0) {
        API.graphql(
          graphqlOperation(searchUserAccountsByOffset, {
            state: "active",
          })
        )
          .then((data) => {
            const response = data.data.searchUserAccountsByOffset.items;
            setOptions(
              response.filter((item) => item.roleName === ADMIN_USER_ROLE)
            );
          })
          .catch((error) => {
            debugLog(
              "get Error searchUserAccountsByOffset at path src/features/MembersMange/OwnerShipTransferDialog/index.js",
              error
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClose = async () => {
    if (step === PROGRESS_STEP.successStep) {
      navigate(location.pathname, { replace: true });
      await signOut();
      window.location.reload();
    }
    ownership.toggle(false);
    if (selectedUserId && selectedUserId) {
      updateQueryParam({
        selectedUserId: null,
        step: null,
      });
    }
  };

  const handleConfirm = async () => {
    if (!selectedUser) return;

    await signOut();
    updateQueryParam();
    window.location.reload();
  };

  const handleBack = () => {
    setStep(PROGRESS_STEP.currentStep);
  };

  const handleNextStep = () => {
    if (selectedUser) setStep(PROGRESS_STEP.loginStep);
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
      {(step === PROGRESS_STEP.currentStep ||
        step === PROGRESS_STEP.loginStep) && (
        <DialogTitle>オーナー権限の変更</DialogTitle>
      )}
      <DialogContent>
        {step === PROGRESS_STEP.currentStep && (
          <>
            <Typography variant="body1" gutterBottom>
              このアカウントの権限を変更します。完了すると、あなたは管理者となり、選択したユーザーにオーナー権限が付与されます。
            </Typography>

            <Autocomplete
              sx={{ width: 300, mt: 2 }}
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name}
              options={options}
              loading={loading}
              value={selectedUser}
              onChange={(_, newValue) => {
                setSelectedUser(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="管理者名"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    },
                  }}
                />
              )}
            />
            {selectedUser && (
              <Card variant="outlined" sx={{ mt: 2 }}>
                <CardContent>
                  <Typography variant="h6">{selectedUser.name}</Typography>
                  <Typography variant="body2">
                    役割: {selectedUser.roleName}
                  </Typography>
                  <Typography variant="body2">
                    メールアドレス: {selectedUser.email}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </>
        )}

        {step === PROGRESS_STEP.loginStep && (
          <>
            <Typography variant="body1" gutterBottom>
              オーナー権限が変更されたことを確認するために再度ログインをお願いします。このステップはアカウントのセキュリティを確保するためのものです。
            </Typography>
          </>
        )}

        {step === PROGRESS_STEP.transferringStep && (
          <Box style={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
              オーナー権限を変更しています...
            </Typography>
          </Box>
        )}

        {step === PROGRESS_STEP.errorStep && (
          <Box style={{ textAlign: "center", padding: "20px" }}>
            <ErrorOutline color="error" sx={{ fontSize: 60 }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              エラーが発生しました!
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
              再試行してください。
            </Typography>
          </Box>
        )}

        {step === PROGRESS_STEP.successStep && (
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <CheckCircle color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              オーナー権限の変更が完了しました！
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
              あなたは管理者に変更されました。
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                padding: "10px 20px",
                fontSize: "16px",
                borderRadius: "30px",
                boxShadow: 2,
                "&:hover": {
                  backgroundColor: "primary.dark",
                  boxShadow: 6,
                },
              }}
              onClick={onClose}
            >
              ログイン
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step === PROGRESS_STEP.currentStep && (
          <>
            <Button
              variant="text"
              onClick={() => {
                ownership.toggle(false);
                navigate(location.pathname, { replace: true });
              }}
              color="secondary"
            >
              キャンセル
            </Button>
            <Button
              variant="text"
              onClick={handleNextStep}
              color="primary"
              disabled={!selectedUser}
              endIcon={<NavigateNextIcon />}
            >
              次へ
            </Button>
          </>
        )}
        {step === PROGRESS_STEP.loginStep && (
          <>
            <>
              <Button onClick={handleBack} color="secondary">
                戻る
              </Button>
              <LoadingButton
                loading={submitting}
                onClick={handleConfirm}
                color="primary"
                disabled={submitting}
              >
                確定
              </LoadingButton>
            </>
          </>
        )}
        {step === PROGRESS_STEP.errorStep && (
          <>
            <Button
              onClick={() => {
                navigate(location.pathname, { replace: true });
              }}
              color="secondary"
            >
              再試行
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default OwnerShipTransferDialog;
