import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { useRef } from "react";
import { useOpener } from "utils/useOpener";
import {
  fromQueryString,
  toQueryString,
  useXGridComponents,
} from "utils/useXGridComponents";
import { useMutation } from "utils/useMutation";
import { searchContainersByOffset } from "api/graphql/queries";
import {
  removeInstallationContainer,
  installContainer,
  transferContainer,
  replaceContainer,
} from "api/graphql/mutations";
import { formatJSDateToISODate } from "utils/format";
import { useNavigate, useLocation } from "react-router-dom";
import { getGridNumericOperators } from "@mui/x-data-grid-pro";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

/**
 * コンテナ管理のリストを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const safekeepingMenu = useOpener();
  const installationMenu = useOpener();
  const installationForm = useOpener();
  const installationFormRef = useRef(null);
  const reinstallationForm = useOpener();
  const reinstallationFormRef = useRef(null);
  const exchangeForm = useOpener();
  const exchangeFormRef = useRef();
  const withdrawalForm = useOpener();
  const withdrawalFormRef = useRef(null);
  const movementForm = useOpener();
  const movememtFormRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queries = new URLSearchParams(useLocation().search);
  const daysElapsedAfterInstallation = queries.get(
    "daysElapsedAfterInstallation"
  );
  // todo: 名前変更
  const handleClick = (row) => (event) => {
    event.stopPropagation();
    if (row?.installationLocationCategory === "Storage") {
      installationMenu.toggle(true);
      installationMenu.changeOptions({
        anchorEl: event.currentTarget,
        value: row,
      });
    } else {
      safekeepingMenu.toggle(true);
      safekeepingMenu.changeOptions({
        anchorEl: event.currentTarget,
        value: row,
      });
    }
  };

  const columns = [
    {
      field: "installationCompanyName",
      headerName: "設置場所の事業者",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
    },
    {
      field: "installationWorkplaceName",
      headerName: "設置場所の事業場",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
    },
    {
      field: "productName",
      headerName: "コンテナ種類",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
    },
    {
      field: "number",
      headerName: "コンテナ管理番号",
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" || operator.value === "equals"
      ),
    },
    {
      field: "daysElapsedAfterInstallation",
      headerName: "経過日数",
      flex: 1,
      renderCell: (params) =>
        `${
          params.row?.daysElapsedAfterInstallation === null
            ? ``
            : `${params.row?.daysElapsedAfterInstallation}日`
        }`,
      type: "number",
      filterOperators: getGridNumericOperators().filter(
        (operator) =>
          operator.value === ">" ||
          operator.value === ">=" ||
          operator.value === "<" ||
          operator.value === "<=" ||
          operator.value === "=" ||
          operator.value === "!="
      ),
    },
    {
      field: "",
      headerName: "",
      minWidth: 50,
      renderCell: (params) => {
        return (
          <IconButton onClick={handleClick(params?.row)}>
            <MoreVertIcon />
          </IconButton>
        );
      },
      filterable: false,
      hideSortIcons: false,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
    },
  ];

  const filterConditions = daysElapsedAfterInstallation
    ? {
        daysElapsedAfterInstallation: {
          gt: daysElapsedAfterInstallation,
        },
      }
    : {};

  const xGridObject = useXGridComponents(
    columns,
    searchContainersByOffset,
    {
      filter: {
        and: [
          {
            state: {
              eq: "active",
            },
          },
          filterConditions,
        ],
      },
    },
    {
      sort: {
        field: "daysElapsedAfterInstallation",
        direction: "desc",
      },
      ...fromQueryString(location.search),
    }
  );

  const handleSelected = (params) => {
    const selected = xGridObject.params.rows.find(
      (row) => row.id === params[0]
    );
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(xGridObject.search),
      },
      {
        replace: true,
      }
    );
    navigate(`${location.pathname}/${selected.id}`);
  };

  const [installationMutation, installationStatus] = useMutation(
    installContainer,
    {
      onCompleted: (data) => {
        xGridObject.functions.refetch();
        safekeepingMenu.toggle(false);
        installationMenu.toggle(false);
        reinstallationForm.toggle(false);
        installationForm.toggle(false);
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録に失敗しました。",
    }
  );

  const [exchangeMutation, exchangeStatus] = useMutation(replaceContainer, {
    onCompleted: (data) => {
      xGridObject.functions.refetch();
      safekeepingMenu.toggle(false);
      installationMenu.toggle(false);
      exchangeForm.toggle(false);
    },
    succeedMessage: "登録しました。",
    errorMessage: "エラーが発生したため、登録に失敗しました。",
  });

  const [withdrawalMutation, withdrawalStatus] = useMutation(
    removeInstallationContainer,
    {
      onCompleted: (data) => {
        xGridObject.functions.refetch();
        safekeepingMenu.toggle(false);
        installationMenu.toggle(false);
        withdrawalForm.toggle(false);
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録に失敗しました。",
    }
  );
  const [moveMutation, moveStatus] = useMutation(transferContainer, {
    onCompleted: (data) => {
      xGridObject.functions.refetch();
      safekeepingMenu.toggle(false);
      installationMenu.toggle(false);
      movementForm.toggle(false);
    },
    succeedMessage: "登録しました。",
    errorMessage: "エラーが発生したため、登録に失敗しました。",
  });

  const handleInstallation = (data) => {
    const {
      container,
      installationWorkplace,
      shippedOn,
      installedOn,
      vehicle,
      personInChargeNameharge,
      remarks,
    } = data;

    installationMutation({
      input: {
        containerId: container.id,
        installationWorkplaceId: installationWorkplace.id,
        shippedOn: formatJSDateToISODate(shippedOn),
        installedOn: formatJSDateToISODate(installedOn),
        vehicleId: vehicle?.id,
        personInChargeName: personInChargeNameharge,
        remarks: remarks,
      },
    });
  };

  const handleReinstallation = (data) => {
    const {
      installationWorkplace,
      shippedOn,
      installedOn,
      vehicle,
      personInChargeNameharge,
      remarks,
    } = data;

    installationMutation({
      input: {
        containerId: installationMenu?.options?.value?.id,
        installationWorkplaceId: installationWorkplace.id,
        shippedOn: formatJSDateToISODate(shippedOn),
        installedOn: formatJSDateToISODate(installedOn),
        vehicleId: vehicle?.id,
        personInChargeName: personInChargeNameharge,
        remarks: remarks,
      },
    });
  };

  const handleClose = () => {
    safekeepingMenu.toggle(false);
    installationMenu.toggle(false);
  };

  const handleExchange = (data) => {
    const {
      replacementContainer,
      storageWorkplace,
      shippedOn,
      replacedOn,
      storedOn,
      vehicle,
      personInChargeName,
      remarks,
    } = data;

    exchangeMutation({
      input: {
        installationContainerId: safekeepingMenu?.options?.value?.id,
        replacementContainerId: replacementContainer.id,
        storageWorkplaceId: storageWorkplace.id,
        shippedOn: formatJSDateToISODate(shippedOn),
        replacedOn: formatJSDateToISODate(replacedOn),
        storedOn: formatJSDateToISODate(storedOn),
        vehicleId: vehicle?.id,
        personInChargeName: personInChargeName,
        remarks: remarks,
      },
    });
  };

  const handleWithdrawal = (data) => {
    const {
      storageWorkplace,
      removedOn,
      storedOn,
      vehicle,
      personInChargeName,
      remarks,
    } = data;

    withdrawalMutation({
      input: {
        containerId: safekeepingMenu?.options?.value?.id,
        storageWorkplaceId: storageWorkplace.id,
        removedOn: formatJSDateToISODate(removedOn),
        storedOn: formatJSDateToISODate(storedOn),
        vehicleId: vehicle?.id,
        personInChargeName: personInChargeName,
        remarks: remarks,
      },
    });
  };

  const handleMove = (data) => {
    const {
      storageWorkplace,
      shippedOn,
      storedOn,
      vehicle,
      personInChargeName,
      remarks,
    } = data;

    moveMutation({
      input: {
        containerId: installationMenu?.options?.value?.id,
        storageWorkplaceId: storageWorkplace.id,
        shippedOn: formatJSDateToISODate(shippedOn),
        storedOn: formatJSDateToISODate(storedOn),
        vehicleId: vehicle?.id,
        personInChargeName: personInChargeName,
        remarks: remarks,
      },
    });
  };

  return render({
    ...props,
    onSelected: handleSelected,
    onClick: handleClick,
    onClose: handleClose,
    safekeepingMenu: safekeepingMenu,
    installationMenu: installationMenu,
    installationFormRef: installationFormRef,
    installationForm: installationForm,
    onInstallation: handleInstallation,
    onReinstallation: handleReinstallation,
    xGridParams: xGridObject.params,
    onExchange: handleExchange,
    exchangeForm: exchangeForm,
    withdrawalForm: withdrawalForm,
    withdrawalFormRef: withdrawalFormRef,
    onWithdrawal: handleWithdrawal,
    onMove: handleMove,
    movementForm: movementForm,
    exchangeFormRef: exchangeFormRef,
    movememtFormRef: movememtFormRef,
    reinstallationForm: reinstallationForm,
    reinstallationFormRef: reinstallationFormRef,
    loading:
      installationStatus.loading ||
      exchangeStatus.loading ||
      withdrawalStatus.loading ||
      moveStatus.loading,
  });
};
