import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { listWasteCollectionSchedulesCount } from "api/graphql/queries";
import { formatDisplayDate } from "utils/format";
import { useXGridComponents } from "utils/useXGridComponents";

const columns = [
  {
    field: "scheduleDate",
    headerName: "回収日",
    width: 150,
    renderCell: (params) =>
      formatDisplayDate({
        source: params.value,
        destFormat: "yyyy/MM/dd (EEE)",
      }),
  },
  {
    field: "unassignedCount",
    headerName: "未配車数",
    flex: 1,
  },
  {
    field: "assignedCount",
    headerName: "配車数合計",
    flex: 1,
  },
  {
    field: "lastUpdatedOn",
    headerName: "最終更新日",
    flex: 1,
    renderCell: (params) => {
      if (params.value === null) {
        return "-";
      }
      const luxonDate = DateTime.fromISO(params.value, { zone: "utc" });
      return luxonDate.toFormat("yyyy/MM/dd");
    },
  },
];

/**
 *
 * @param {Object} props - コンポーネントに渡されるプロパティ
 * @param {Function} props.render - レンダリング関数
 * @param {...Object} props - その他のプロパティ
 *
 * @returns {JSX.Element}
 *
 */

const LIMIT = 20;
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const obj = useXGridComponents(
    columns,
    listWasteCollectionSchedulesCount,
    {
      other: {
        sortDirection: "DESC",
      },
    },
    {
      pageSize: LIMIT,
    }
  );

  const handleSelected = (params) => {
    const row = obj.params.rows
      .map((d, i) => ({ id: i, ...d }))
      .find((i) => i.id === params[0]);
    navigate(`/allocation/schedule?date=${row.scheduleDate}`);
  };

  return render({
    xGridParams: obj.params,
    onSelected: handleSelected,
    ...props,
  });
};
