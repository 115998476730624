import { useState, useRef, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { closeDrawer, openDrawer } from "ducks/Drawer";
import { add as addAlert } from "ducks/Alert";
import {
  selector as dashboardWidgetsSelector,
  makeAllWidgetResizable,
  makeAllWidgetUnresizable,
  getDashboardWidgetsData,
  getUserDashboardWidgetsData,
  updateUserDashboardWidgetsData,
  discardLayoutChange,
} from "ducks/Widgets";

/**
 * Containerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 *
 */
export const Container = ({ render, ...props }) => {
  const [openCustomizeDrawer, setOpenCustomizeDrawer] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const widgetsRef = useRef({});
  const { userPreferences, loading } = useSelector(dashboardWidgetsSelector);

  useEffect(() => {
    dispatch(getDashboardWidgetsData());
    dispatch(getUserDashboardWidgetsData());
  }, [dispatch]);

  const handleWidgetSelect = (widgetId) => {
    setSelectedWidget(widgetId);
    widgetsRef.current[widgetId]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (selectedWidget) {
      const timer = setTimeout(() => setSelectedWidget(null), 1000);
      return () => clearTimeout(timer);
    }
  }, [selectedWidget]);

  const handleSave = async () => {
    const payload = userPreferences.items.map((item) => ({
      id: item.id,
      layout: {
        ...item.layout,
        i: undefined,
      },
      configurations: JSON.stringify(item.configurations),
    }));
    const response = await dispatch(updateUserDashboardWidgetsData(payload));
    if (response.error) {
      dispatch(
        addAlert({
          value: "変更を保存できませんでした。",
          severity: "error",
        })
      );
    } else {
      dispatch(
        addAlert({
          value: "変更は正常に保存されました。",
          severity: "success",
        })
      );
      if (hasChanged) {
        setOpenAlert(false);
        setOpenCustomizeDrawer(!openCustomizeDrawer);
        dispatch(openDrawer());
        return;
      }
      setOpenCustomizeDrawer(!openCustomizeDrawer);
      dispatch(openDrawer());
    }
  };

  const hasChanged = useMemo(() => {
    if (
      userPreferences.items.length !== userPreferences.lastFetchedItems.length
    )
      return true;
    const areArraysEqual = _.isEmpty(
      _.differenceWith(
        userPreferences.items,
        userPreferences.lastFetchedItems,
        _.isEqual
      )
    );

    return !areArraysEqual;
  }, [userPreferences.items, userPreferences.lastFetchedItems]);

  const toggleDrawer = () => {
    setOpenCustomizeDrawer(!openCustomizeDrawer);
    dispatch(closeDrawer());
  };

  const handleCustomizeDrawerToggle = () => {
    window.scroll(0, 0);
    if (openCustomizeDrawer) {
      if (hasChanged) {
        setOpenAlert(true);
        return;
      }
      dispatch(makeAllWidgetUnresizable());
      toggleDrawer();
    } else {
      dispatch(makeAllWidgetResizable());
      toggleDrawer();
    }
  };

  const handleDiscard = () => {
    dispatch(discardLayoutChange());
    setOpenAlert(false);
    toggleDrawer();
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const setWidgetsRef = (node) => {
    if (node) {
      Array.from(node.children).forEach((child) => {
        widgetsRef.current[child.id] = child;
      });
    }
  };

  return render({
    handleCustomizeDrawerToggle,
    openCustomizeDrawer,
    loading,
    handleSave,
    openAlert,
    handleDiscard,
    handleClose,
    handleWidgetSelect,
    selectedWidget,
    setWidgetsRef,
    isUserWidgetEmpty: !userPreferences.items.length,
    ...props,
  });
};
