import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import Presententer from "./Presententer";

/**
 * ShowcaseLayoutコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Function} props.onBreakpointChange - ブレークポイント変更時のコールバック関数
 * @param {Object} props.gridProps - グリッドのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうか
 * @param {boolean} props.openCustomizeDrawer - カスタマイズドロワーが開いているかどうか
 * @param {Object} props.size - サイズのプロパティ
 * @param {Object} props.selectedWidget - 選択されたウィジェット
 * @param {Function} props.setWidgetsRef - ウィジェットの参照を設定する関数
 */
export const ShowcaseLayout = containerPresententer(Container, Presententer);
