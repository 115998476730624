import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selector as dashboardWidgetsSelector,
  removeWidgetFromUserPreference,
  resetAllWidgets,
} from "ducks/Widgets";
import { useStyles } from "./styles";

/**
 * Containerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Function} props.onBreakpointChange - ブレークポイント変更時のコールバック関数
 * @param {Object} props.gridProps - グリッドのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうか
 * @param {boolean} props.openCustomizeDrawer - カスタマイズドロワーが開いているかどうか
 * @param {Object} props.size - サイズのプロパティ
 * @param {Object} props.selectedWidget - 選択されたウィジェット
 * @param {Function} props.setWidgetsRef - ウィジェットの参照を設定する関数
 */
export const Container = ({ render, ...props }) => {
  const dispatch = useDispatch();
  const { userPreferences } = useSelector(dashboardWidgetsSelector);
  const classes = useStyles();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onRemoveFromCanvas = (id) => {
    dispatch(removeWidgetFromUserPreference(id));
  };

  const onLayoutChange = (data) => {
    const items = data.map((item) => {
      return {
        i: item.i,
        h: item.h,
        w: item.w,
        x: item.x,
        y: item.y,
        isResizable: item.isResizable,
        maxH: item.maxH,
        maxW: item.maxW,
        minH: item.minH,
        minW: item.minW,
        static: item.static,
      };
    });
    dispatch(resetAllWidgets(items));
  };

  return render({
    classes,
    mounted,
    onRemoveFromCanvas,
    onLayoutChange,
    layouts: userPreferences.items,
    ...props,
  });
};
