import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { searchIndividualityWasteCollectionOrdersByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { selector as userWidgetsSelector } from "ducks/Widgets";
import { sampleData } from "./sample";
import { debugLog } from "utils/log";

/**
 * Containerコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうか
 * @param {Function} props.render - 子コンポーネントをレンダリングするための関数
 * @param {string} props.widgetId - 表示するウィジェットのID
 */
export const Container = ({ render, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    widgets: { items: widgetsArray },
  } = useSelector(userWidgetsSelector);

  const widget = widgetsArray[props.widgetId];
  const navigate = useNavigate();

  const load = useCallback(() => {
    setLoading(true);
    API.graphql(
      graphqlOperation(searchIndividualityWasteCollectionOrdersByOffset, {
        offset: 0,
        limit: 5,
        sort: {
          field: "createdAt",
          direction: "desc",
        },
      })
    )
      .then((response) => {
        setData(
          response.data.searchIndividualityWasteCollectionOrdersByOffset.items.map(item => {
            return {
              ...item,
              id: item.acceptingOrderId
            }
          })
        );
      })
      .catch((err) => {
        debugLog("searchIndividualityWasteCollectionOrdersByOffset: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (props.editMode) {
      setData(sampleData);
    } else {
      load();
    }
  }, [props.editMode, load]);

  const handleMoreData = () => {
    navigate("/order/spot");
  };

  const onCellClick = (id) => {
    if (props.editMode) return;
    navigate("/order/spot/" + id);
  }

  return render({
    ...props,
    data,
    loading,
    handleMoreData,
    name: widget?.name,
    onCellClick: onCellClick,
  });
};
