import {
  Container,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Card,
  Skeleton,
  Stack,
  Dialog,
  DialogActions,
  IconButton,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { CustomizeSettingIcon } from "views/atoms";

import EmptyStateImage from "assets/svg/EmptyBox.svg";

import {
  AnimatedAlert,
  AnimatedSideDrawer,
  ShowcaseLayout,
} from "./Components";

import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

const DRAWERWIDTH = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, mr }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -mr,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative",
  })
);

/**
 * Presententerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.openCustomizeDrawer - カスタマイズドロワーが開いているかどうか
 * @param {boolean} props.loading - ローディング状態かどうか
 * @param {Function} props.handleCustomizeDrawerToggle - カスタマイズドロワーの開閉を切り替える関数
 * @param {boolean} props.openAlert - アラートが開いているかどうか
 * @param {Function} props.handleSave - 保存操作を処理する関数
 * @param {Function} props.handleDiscard - 破棄操作を処理する関数
 * @param {Function} props.handleClose - 閉じる操作を処理する関数
 * @param {Function} props.handleWidgetSelect - ウィジェット選択を処理する関数
 * @param {Object} props.selectedWidget - 選択されたウィジェット
 * @param {Function} props.setWidgetsRef - ウィジェットの参照を設定する関数
 * @param {boolean} props.isUserWidgetEmpty - ユーザーウィジェットが空かどうか
 */
export const Presententer = ({
  openCustomizeDrawer,
  loading,
  handleCustomizeDrawerToggle,
  openAlert,
  handleSave,
  handleDiscard,
  handleClose,
  handleWidgetSelect,
  selectedWidget,
  setWidgetsRef,
  isUserWidgetEmpty,
}) => {
  const theme = useTheme();
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const getDrawerWidth = () => {
    if (isXLargeScreen) {
      return DRAWERWIDTH;
    }
    return DRAWERWIDTH * 0.8;
  };

  const drawerWidth = getDrawerWidth();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Main open={openCustomizeDrawer} mr={drawerWidth}>
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            width: {
              lg: "100%",
              xl: openCustomizeDrawer ? "100%" : "90%",
            },
            margin: {
              xs: 0,
              xl: "0 auto",
            },
            padding: {
              xs: 0,
              xl: 2,
            },
          }}
        >
          {loading && (
            <Stack
              useFlexGap
              direction="row"
              sx={{ flexWrap: "wrap" }}
              spacing={2}
            >
              {Array(9)
                .fill(null)
                .map((_, index) => {
                  return (
                    <Skeleton
                      key={index}
                      width="25%"
                      variant="rectangular"
                      height={200}
                    />
                  );
                })}
            </Stack>
          )}
          {!loading && isUserWidgetEmpty && !openCustomizeDrawer && (
            <Stack
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <img width={150} src={EmptyStateImage} alt="empty-dashboard" />
              <Typography
                variant="h5"
                mt={2}
                fontWeight={700}
                color="textSecondary"
              >
                ウィジェットがありません
              </Typography>
              <Typography variant="h6" color="textSecondary">
                ウィジェットを追加してダッシュボードをカスタマイズしましょう。
              </Typography>
            </Stack>
          )}
          {!loading && (
            <>
              {openCustomizeDrawer && (
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 5,
                    p: 1,
                    zIndex: 100,
                    position: "sticky",
                    top: 70,
                    right: 0,
                    width: "100%",
                  }}
                >
                  <AnimatedAlert animate variant="outlined" severity="warning">
                    現在、編集モードです。ここに表示されているデータはサンプルデータのみです。実際のデータは異なる場合があります。
                  </AnimatedAlert>
                  <LoadingButton
                    disabled={loading}
                    loading={loading}
                    variant="outlined"
                    onClick={handleSave}
                  >
                    変更を保存する
                  </LoadingButton>
                </Card>
              )}
              <ShowcaseLayout
                editMode={openCustomizeDrawer}
                openCustomizeDrawer={openCustomizeDrawer}
                selectedWidget={selectedWidget}
                setWidgetsRef={setWidgetsRef}
              ></ShowcaseLayout>
            </>
          )}
        </Container>
      </Main>

      <AnimatedSideDrawer
        open={openCustomizeDrawer}
        handleDrawerClose={handleCustomizeDrawerToggle}
        DRAWERWIDTH={drawerWidth}
        handleWidgetSelect={handleWidgetSelect}
      />

      <CustomizeSettingIcon onClick={handleCustomizeDrawerToggle} />
      <Dialog open={openAlert} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          変更を保存しますか？
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Typography variant="body1" align="center">
            いくつかの項目を移動しました。変更を保存しない場合、加えた変更が失われます。変更を保存しますか、それとも破棄しますか？
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleDiscard}
            color="error"
            variant="outlined"
          >
            破棄
          </Button>
          <Button
            disabled={loading}
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
