import {
  CommuteOutlined,
  Dashboard as DashboardIcon,
  DeleteOutlineOutlined,
  DescriptionOutlined,
  DomainOutlined,
  DoubleArrowOutlined,
  DvrOutlined,
  EqualizerOutlined,
  LocalShippingOutlined,
  LocationOn,
  PersonOutlined,
  PeopleOutlineOutlined,
  Storage,
  Warning,
  TakeoutDiningOutlined,
  PlaylistAddCheckOutlined,
  TaskOutlined,
  Groups as GroupsIcon,
  GroupAddOutlined,
  FactCheckOutlined,
} from "@mui/icons-material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import {
  List as ElectronicManifstList,
  ElectronicManifestView,
} from "views/pages/Slip/ElectronicManifest";
import {
  Dashboard,
  SlipMenuSelect,
  PaperManifest,
  PaperManifestDetails,
  OrderMenuSelect,
  RegularlyDetails,
  RegularlyList,
  SpotDetails,
  SpotList,
  Settings as AllocationSetting,
  Schedule as AllocationSchedule,
  CollectList,
  CollectView,
  Aggregation,
  SystemSubscriber,
  AlertContracts,
  AlertLicenses,
} from "views/pages";
import {
  Corporation,
  FlowDetails,
  FlowLists,
  LicenseList,
  MenuSelect as MasterMenuSelect,
  ContractDetails,
  ContractLists,
  PartnerDetails,
  PartnerList,
  PartnerBulkCreate,
  VehicleDetails,
  VehicleList,
  WasteDetails,
  WorkplaceDetails,
  WorkplaceDetailsInPartner,
  CollectionPrecautionDetails,
  WorkplaceList,
  GeneralWasteLicenseDetails,
  IndustrialWasteLicenseDetails,
  Container,
  ContainerCategoryDetails,
  ContainerDetails as ContainerDetailsMaster,
} from "views/pages/Masters";
import { WasteFlowDetails } from "views/pages/Masters/Partner";
import { CollectStart } from "views/pages/Mobile";
import { Dashboard as MobileDashboard } from "views/pages/Mobile";
import { InspectionList as MobileVehicleInspectionList } from "views/pages/Mobile/Vehicles/InspectionList";
import { Inspection as MobileVehicleInspection } from "views/pages/Mobile/Vehicles/Inspection";
import { InspectionDetails as MobileVehicleInspectionDetails } from "views/pages/Mobile/Vehicles/InspectionDetails";
import { AllocationMenuSelect } from "views/templates/Allocation";
import TakeoutDiningOutlinedIcon from "@mui/icons-material/TakeoutDiningOutlined";
import { ContainerDetails } from "views/pages/ContainerManage/ContainerDetails";
import { List as ContainerManageList } from "views/pages/ContainerManage";
import { List as VehiclesList } from "views/pages/Vehicles/List";
import { Details as VehiclesDetails } from "views/pages/Vehicles/Details";
import { MenuSelect as VehiclesMenuSelect } from "views/pages/Vehicles/MenuSelect";
import {
  Inspection as VehiclesPeriodicInspection,
  Maintenance as VehiclesMaintenance,
  Safety as VehiclesSafety,
  SafetyDetails as VehiclesSafetyDetails,
  PeriodicInspections as VehiclesPeriodicInspections,
  PeriodicInspectionsDetails as VehiclesPeriodicInspectionDetails,
  Maintenances as VehiclesMaintenances,
  MaintenancesDetails as VehiclesMaintenancesDetails,
  Safeties as VehiclesSafeties,
  SafetiesDetails as VehiclesSafetiesDetails,
  SafetiesDetailsMaintenances as VehiclesSafeteisDetailsMaintenances,
} from "views/pages/Vehicles";
import CommuteIcon from "@mui/icons-material/Commute";
import {
  List as CheckSheetList,
  Details as CheckSheetDetails,
} from "views/pages/Masters/CheckSheet";
import { List as MobileCollectList } from "views/pages/Mobile/Collect";
import {
  List as MembersManageList,
  Details as MembersManageDetails,
} from "views/pages/MembersManage";
import {
  MenuSelect as ExternalServiceLinkageMenu,
  Jwnet as ExternalServiceLinkageJwnet,
} from "views/pages/ExternalServiceLinkage";
import SdCardAlertOutlinedIcon from "@mui/icons-material/SdCardAlertOutlined";

/**
 * ページの全ルート情報を表します
 */

// ダッシュボードのルート情報
export const routesDashboard = [
  {
    title: "ダッシュボード",
    icon: <DashboardIcon />,
    showBreadcrumbs: false,
    path: "/",
    element: <Dashboard />,
  },
];

// 日常営業のルート
export const routesBusiness = [
  {
    title: "受注",
    shortTitle: "受注",
    icon: <DvrOutlined />,
    showBreadcrumbs: true,
    path: "/order",
    element: <OrderMenuSelect />,
  },
  {
    title: "スポット回収一覧",
    icon: <DvrOutlined />,
    showBreadcrumbs: true,
    path: "/order/spot",
    element: <SpotList />,
  },
  {
    title: "スポット回収詳細",
    icon: <DvrOutlined />,
    showBreadcrumbs: true,
    path: "/order/spot/:id",
    element: <SpotDetails />,
  },
  {
    title: "定期回収一覧",
    icon: <DvrOutlined />,
    showBreadcrumbs: true,
    path: "/order/regularly",
    element: <RegularlyList />,
  },
  {
    title: "定期回収詳細",
    icon: <DvrOutlined />,
    showBreadcrumbs: true,
    path: "/order/regularly/:id",
    element: <RegularlyDetails />,
  },
  {
    title: "配車",
    shortTitle: "配車",
    icon: <LocalShippingOutlined />,
    showBreadcrumbs: true,
    path: "/allocation",
    element: <AllocationMenuSelect />,
  },
  {
    title: "配車計画",
    icon: <LocalShippingOutlined />,
    showBreadcrumbs: true,
    path: "/allocation/setting",
    element: <AllocationSetting />,
  },
  {
    title: "配車表",
    shortTitle: "配車",
    icon: <LocalShippingOutlined />,
    showBreadcrumbs: true,
    path: "/allocation/schedule",
    element: <AllocationSchedule />,
  },
  {
    title: "コンテナ管理",
    shortTitle: "コンテナ管理",
    icon: <TakeoutDiningOutlinedIcon />,
    showBreadcrumbs: true,
    path: "/container-list",
    element: <ContainerManageList />,
  },
  {
    title: "コンテナ詳細",
    icon: <TakeoutDiningOutlinedIcon />,
    showBreadcrumbs: true,
    path: "/container-list/:id",
    element: <ContainerDetails />,
  },
  {
    title: "車輛管理",
    shortTitle: "車輛管理",
    icon: <CommuteIcon />,
    showBreadcrumbs: true,
    path: "/vehicles-menu",
    element: <VehiclesMenuSelect />,
  },
  {
    title: "車輛一覧",
    icon: <CommuteIcon />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/list",
    element: <VehiclesList />,
  },
  {
    title: "車輛詳細",
    icon: <CommuteIcon />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/list/:id",
    element: <VehiclesDetails />,
  },
  {
    title: "定期点検記録簿",
    icon: <PlaylistAddCheckOutlined />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/periodic-inspections",
    element: <VehiclesPeriodicInspections />,
  },
  {
    title: "定期点検詳細",
    icon: <CommuteIcon />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/list/:id/periodic-inspection/:inspectionId",
    element: <VehiclesPeriodicInspection />,
  },
  {
    title: "定期点検記録簿詳細",
    icon: <PlaylistAddCheckOutlined />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/periodic-inspections/:inspectionId",
    element: <VehiclesPeriodicInspectionDetails />,
  },
  {
    title: "車検履歴詳細",
    icon: <CommuteIcon />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/list/:id/safeties/:safetiesId",
    element: <VehiclesSafety />,
  },
  {
    title: "整備記録",
    icon: <CommuteIcon />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/list/:id/safeties/:safetiesId/maintenances/:maintenanceId",
    element: <VehiclesSafetyDetails />,
  },
  {
    title: "整備記録詳細",
    icon: <CommuteIcon />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/list/:id/maintenances/:maintenacesId",
    element: <VehiclesMaintenance />,
  },
  {
    title: "整備記録簿",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/maintenances",
    element: <VehiclesMaintenances />,
  },
  {
    title: "整備記録簿詳細",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/maintenances/:maintenanceId",
    element: <VehiclesMaintenancesDetails />,
  },
  {
    title: "車検記録簿",
    icon: <TaskOutlined />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/safeties",
    element: <VehiclesSafeties />,
  },
  {
    title: "車検記録簿詳細",
    icon: <TaskOutlined />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/safeties/:safetyId",
    element: <VehiclesSafetiesDetails />,
  },
  {
    title: "整備履歴",
    icon: <TaskOutlined />,
    showBreadcrumbs: true,
    path: "/vehicles-menu/safeties/:safetyId/maintenances/:maintenanceId",
    element: <VehiclesSafeteisDetailsMaintenances />,
  },
  {
    title: "回収記録",
    shortTitle: "回収記録",
    icon: <DescriptionOutlined />,
    path: "/collect",
    element: <CollectList />,
    showBreadcrumbs: true,
  },
  {
    title: "回収記録詳細",
    shortTitle: "回収記録詳細",
    icon: <DescriptionOutlined />,
    path: "/collect/:id",
    element: <CollectView />,
    showBreadcrumbs: true,
  },
  {
    title: "伝票入力",
    shortTitle: "伝票入力",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/slip",
    element: <SlipMenuSelect />,
  },
  {
    title: "電子マニフェスト",
    shortTitle: "伝票入力",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/slip/electronic",
    element: <ElectronicManifstList />,
  },
  {
    title: "電子マニフェスト詳細",
    shortTitle: "伝票入力",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/slip/electronic/:id",
    element: <ElectronicManifestView />,
  },
  {
    title: "紙マニフェスト",
    shortTitle: "伝票入力",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/slip/paper",
    element: <PaperManifest />,
  },
  {
    title: "紙マニフェスト詳細",
    shortTitle: "伝票入力",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/slip/paper/:id",
    element: <PaperManifestDetails />,
  },
  {
    title: "集計",
    shortTitle: "集計",
    icon: <EqualizerOutlined />,
    showBreadcrumbs: true,
    path: "/aggregation",
    element: <Aggregation />,
  },
];

// 管理機能のルート
export const routesManagement = [
  {
    title: "マスタ管理",
    icon: <Storage />,
    showBreadcrumbs: true,
    path: "/master",
    element: <MasterMenuSelect />,
  },
  {
    title: "自社事業者情報",
    icon: <PersonOutlined />,
    showBreadcrumbs: true,
    path: "/master/corporation",
    element: <Corporation />,
  },
  {
    title: "自社事業場一覧",
    icon: <DomainOutlined />,
    showBreadcrumbs: true,
    path: "/master/workplace",
    element: <WorkplaceList />,
  },
  {
    title: "自社事業場情報",
    icon: <DomainOutlined />,
    showBreadcrumbs: true,
    path: "/master/workplace/:id",
    element: <WorkplaceDetails />,
  },
  {
    title: "車輌一覧",
    icon: <CommuteOutlined />,
    showBreadcrumbs: true,
    path: "/master/vehicle",
    element: <VehicleList />,
  },
  {
    title: "車輌情報",
    icon: <CommuteOutlined />,
    showBreadcrumbs: true,
    path: "/master/vehicle/:id",
    element: <VehicleDetails />,
  },
  {
    title: "廃棄物許可証一覧",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/master/license/waste",
    element: <LicenseList />,
  },
  {
    title: "廃棄物許可証情報",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/master/license/waste/industrial/:id",
    element: <IndustrialWasteLicenseDetails />,
  },
  {
    title: "廃棄物許可証情報",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/master/license/waste/general/:id",
    element: <GeneralWasteLicenseDetails />,
  },
  {
    title: "処理フロー一覧",
    icon: <DoubleArrowOutlined />,
    showBreadcrumbs: true,
    path: "/master/flow",
    element: <FlowLists />,
  },
  {
    title: "処理フロー情報",
    icon: <DoubleArrowOutlined />,
    showBreadcrumbs: true,
    path: "/master/flow/:id",
    element: <FlowDetails />,
  },
  {
    title: "取引先一覧",
    icon: <PeopleOutlineOutlined />,
    showBreadcrumbs: true,
    path: "/master/partner",
    element: <PartnerList />,
  },
  {
    title: "一括登録",
    icon: <GroupAddOutlined />,
    showBreadcrumbs: true,
    path: "/master/partner/create-bulk",
    element: <PartnerBulkCreate />,
  },
  {
    title: "取引先情報",
    icon: <PersonOutlined />,
    showBreadcrumbs: true,
    path: "/master/partner/:id",
    element: <PartnerDetails />,
  },
  {
    title: "事業場情報",
    icon: <DomainOutlined />,
    showBreadcrumbs: true,
    path: "/master/partner/:id/workplace/:workplaceId",
    element: <WorkplaceDetailsInPartner />,
  },
  {
    title: "処理フロー詳細",
    icon: <DomainOutlined />,
    showBreadcrumbs: true,
    path: "/master/partner/:id/workplace/:workplaceId/waste/:wasteId/flow/:flowId",
    element: <WasteFlowDetails />,
  },
  {
    title: "契約情報",
    icon: <DescriptionOutlined />,
    showBreadcrumbs: true,
    path: "/master/partner/:id/contract/:contractId",
    element: <ContractDetails />,
  },
  {
    title: "廃棄物情報",
    icon: <DeleteOutlineOutlined />,
    showBreadcrumbs: true,
    path: "/master/partner/:id/workplace/:workplaceId/waste/:wasteId",
    element: <WasteDetails />,
  },
  {
    title: "回収注意点情報",
    icon: <Warning />,
    showBreadcrumbs: true,
    path: "/master/partner/:partnerId/workplace/:workplaceId/precaution/collection/:id",
    element: <CollectionPrecautionDetails />,
  },
  {
    title: "コンテナ種類一覧",
    icon: <TakeoutDiningOutlined />,
    showBreadcrumbs: true,
    path: "/master/container/category/list",
    element: <Container />,
  },
  {
    title: "コンテナ種類詳細",
    icon: <TakeoutDiningOutlined />,
    showBreadcrumbs: true,
    path: "/master/container/category/list/:id",
    element: <ContainerCategoryDetails />,
  },
  {
    title: "コンテナ詳細",
    icon: <TakeoutDiningOutlined />,
    showBreadcrumbs: true,
    path: "/master/container/category/list/:id/product/:productId",
    element: <ContainerDetailsMaster />,
  },
  {
    title: "チェックシート一覧",
    icon: <></>,
    showBreadcrumbs: true,
    path: "/master/checksheets",
    element: <CheckSheetList />,
  },
  {
    title: "チェックシート詳細",
    icon: <></>,
    showBreadcrumbs: true,
    path: "/master/checksheets/:id",
    element: <CheckSheetDetails />,
  },
  {
    title: "メンバー管理",
    icon: <GroupsIcon />,
    showBreadcrumbs: true,
    path: "/members",
    element: <MembersManageList />,
  },
  {
    title: "メンバー詳細",
    icon: <></>,
    showBreadcrumbs: true,
    path: "/members/:id",
    element: <MembersManageDetails />,
  },
  {
    title: "加入者情報",
    icon: <AdminPanelSettingsOutlinedIcon />,
    showBreadcrumbs: false,
    path: "/systemSubscribers",
    element: <SystemSubscriber />,
  },
  {
    title: "外部接続サービス設定",
    icon: <SettingsOutlinedIcon />,
    showBreadcrumbs: true,
    path: "/link",
    element: <ExternalServiceLinkageMenu />,
  },
  {
    title: "JWNET",
    icon: <></>,
    showBreadcrumbs: true,
    path: "/link/jwnet",
    element: <ExternalServiceLinkageJwnet />,
  },
  {
    title: "許可証アラート",
    icon: <SdCardAlertOutlinedIcon />,
    showBreadcrumbs: true,
    path: "/alert-licenses",
    element: <AlertLicenses />,
  },
  {
    title: "契約書アラート",
    icon: <SdCardAlertOutlinedIcon />,
    showBreadcrumbs: true,
    path: "/alert-contracts",
    element: <AlertContracts />,
  },
  {
    title: "契約書一覧",
    icon: <FactCheckOutlined />,
    showBreadcrumbs: true,
    path: "/master/contracts/list",
    element: <ContractLists />,
  },
];

//モバイルのルート
export const routesFooterMobile = [
  {
    title: "受注",
    icon: <DvrOutlined />,
    path: "/orders",
    element: () => <>{"受注"}</>,
  },
  {
    title: "配車",
    icon: <LocalShippingOutlined />,
    path: "/vehicleDispatch",
    element: () => <>{"配車"}</>,
  },
  {
    title: "回収",
    icon: <LocationOn />,
    path: "/collect-mobile",
    element: <MobileCollectList />,
  },
  {
    title: "回収を開始する",
    icon: <LocationOn />,
    path: "/mobile-collect/:id",
    element: <CollectStart />,
  },
  {
    title: "ダッシュボード",
    icon: <DescriptionOutlined />,
    path: "/",
    element: <MobileDashboard />,
  },
  {
    title: "車輛点検一覧",
    icon: <></>,
    path: "/vehicle-inspection-list",
    element: <MobileVehicleInspectionList />,
  },
  {
    title: "車輛点検詳細",
    icon: <></>,
    path: "/vehicle-inspection-list/:id",
    element: <MobileVehicleInspection />,
  },
  {
    title: "車輛点検詳細",
    icon: <></>,
    path: "/vehicle-inspection-list/:id/inspections/:inspectionId",
    element: <MobileVehicleInspectionDetails />,
  },
];

/**
 * サインメニューに表示するルート情報を取得します。
 * @param {array} routes 元ルート情報配列
 * @returns {array} ルート情報配列
 */
export const toSideMenu = (routes) => {
  return routes
    .filter((route) => route.title !== "ページが見つかりませんでした")
    .filter((route) => route.path.match(/\//g).length === 1);
};
const routes = [...routesDashboard, ...routesBusiness, ...routesManagement];

export default routes;
