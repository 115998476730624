import { useTheme } from "@mui/material";

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {boolean} [props.animate=false] - アラートボーダーをアニメーションするかどうか。
 * @returns {JSX.Element} アニメーションされたアラートコンポーネント。
 */
export const Container = ({ render, ...props }) => {
  const theme = useTheme();

  return render({
    theme,
    ...props,
  });
};
