import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * TodaysCourseStaticsコンポーネント
 *
 * @param {Object} props - プロパティ
 * @param {Function} props.render - レンダリング関数
 * @param {string} props.widgetId - ウィジェットID
 * @param {boolean} props.editMode - 編集モードフラグ
 *
 * @returns {JSX.Element} レンダリングされたコンポーネント
 *
 */
export const TodaysCourseStatics = containerPresententer(
  Container,
  Presententer
);
