import {
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  Button,
  FormLabel,
  keyframes,
} from "@mui/material";
import {
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart,
} from "recharts";
import SyncIcon from "@mui/icons-material/Sync";
import { LoadingSkeleton } from "views/pages/Dashboard/Components";

import { DatePicker } from "views/atoms";
import { modeList } from "utils/Context";

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

/**
 * Presententerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.loading - ローディング状態を示すフラグ
 * @param {Object} props.value - グラフに表示するデータ
 * @param {Object} props.dateRange - 日付範囲のオブジェクト
 * @param {function} props.onChangeRange - 日付範囲変更時のコールバック関数
 * @param {string} props.mode - 集計の種類
 * @param {function} props.onChangeMode - 集計の種類変更時のコールバック関数
 * @param {boolean} props.syncing - 同期中状態を示すフラグ
 * @param {function} props.handleSync - 同期ボタン押下時のコールバック関数
 *
 * @returns {JSX.Element} PresententerコンポーネントのJSX要素
 */
export const Presententer = ({
  loading,
  value,
  dateRange,
  onChangeRange,
  mode,
  onChangeMode,
  syncing,
  handleSync,
  onChangeChartType,
  chartType,
  chartTypes,
}) => {
  if (loading) return <LoadingSkeleton loading size={400} />;

  return (
    <>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          height: theme.spacing(5),
          mb: theme.spacing(1),
        })}
      >
        <Typography color="textSecondary" gutterBottom>
          回収量
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormLabel
              sx={{
                mr: 1,
              }}
            >
              グラフタイプ
            </FormLabel>
            <FormControl sx={{ minWidth: 95, display: "flex" }} size="small">
              <Select value={chartType} onChange={onChangeChartType}>
                {chartTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormLabel
              sx={{
                mr: 1,
              }}
            >
              日付
            </FormLabel>

            <DatePicker
              value={dateRange?.start}
              onChange={onChangeRange("start")}
              inputVariant="standard"
              size="small"
              width={143}
            />
            <Box px={1} display="flex" alignItems="center">
              ～
            </Box>
            <DatePicker
              value={dateRange?.end}
              onChange={onChangeRange("end")}
              inputVariant="standard"
              size="small"
              width={143}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormLabel
              sx={{
                mr: 1,
                fontSize: "0.875rem",
              }}
            >
              集計の種類
            </FormLabel>
            <FormControl sx={{ minWidth: 110, display: "flex" }}>
              <Select onChange={onChangeMode} value={mode} size="small">
                {modeList.map((mode) => (
                  <MenuItem value={mode.id} key={mode.id}>
                    {mode.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              ml: 1,
            }}
          >
            <Button onClick={handleSync} variant="outlined" color="primary">
              <SyncIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  animation: syncing
                    ? `${rotateAnimation} 2s linear infinite`
                    : "none",
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: `calc(100% - ${theme.spacing(6)})`,
          width: "100%",
        })}
      >
        <ResponsiveContainer width="100%" height="100%">
          {chartType === chartTypes[0].value && (
            <ComposedChart data={value?.items}>
              <XAxis dataKey="date" padding={{ right: 50, left: 50 }} />
              <YAxis
                yAxisId={1}
                label={{ value: "回収量", angle: -90, dx: -20 }}
              />
              <Tooltip />
              <CartesianGrid strokeDasharray="3 3" />
              {value?.header?.kg === true && (
                <Bar
                  yAxisId={1}
                  dataKey="kg"
                  name="kg"
                  barSize={50}
                  fill="#8884d8"
                />
              )}
              {value?.header?.t === true && (
                <Bar
                  yAxisId={1}
                  dataKey="t"
                  name="t"
                  barSize={50}
                  fill="#D984D5"
                />
              )}

              {value?.header?.m3 === true && (
                <Bar
                  yAxisId={1}
                  dataKey="m3"
                  name="m3"
                  barSize={50}
                  fill="#D98884"
                />
              )}
              {value?.header?.["リットル"] === true && (
                <Bar
                  yAxisId={1}
                  dataKey="リットル"
                  name="リットル"
                  barSize={50}
                  fill="#D5D984"
                />
              )}
              {value?.header?.["個・台"] === true && (
                <Bar
                  yAxisId={1}
                  dataKey="個・台"
                  name="個・台"
                  barSize={50}
                  fill="#84D988"
                />
              )}
            </ComposedChart>
          )}
          {chartType === chartTypes[1].value && (
            <LineChart
              width={730}
              height={250}
              data={value?.items}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="date" padding={{ right: 50, left: 50 }} />
              <YAxis
                yAxisId={1}
                label={{ value: "回収量", angle: -90, dx: -20 }}
              />
              <Tooltip />
              <CartesianGrid strokeDasharray="3 3" />
              <Line
                yAxisId={1}
                type="monotone"
                dataKey="kg"
                name="kg"
                stroke="#8884d8"
              />
              <Line
                yAxisId={1}
                type="monotone"
                dataKey="t"
                name="t"
                stroke="#D984D5"
              />
              <Line
                yAxisId={1}
                type="monotone"
                dataKey="m3"
                name="m3"
                stroke="#D98884"
              />
              <Line
                yAxisId={1}
                type="monotone"
                dataKey="リットル"
                name="リットル"
                stroke="#D5D984"
              />
              <Line
                yAxisId={1}
                type="monotone"
                dataKey="個・台"
                name="個・台"
                stroke="#84D988"
              />
            </LineChart>
          )}
        </ResponsiveContainer>
      </Box>
    </>
  );
};
