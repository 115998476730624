import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * AnimatedSideDrawerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.open - ドロワーが開いているかどうか
 * @param {number} props.DRAWERWIDTH - ドロワーの幅
 * @param {Function} props.handleWidgetSelect - ウィジェット選択を処理する関数
 * @param {Function} props.onChangeWidgetStatus - ウィジェットのステータスを変更する関数
 */
export const AnimatedSideDrawer = containerPresententer(
  Container,
  Presententer
);
