import React from "react";
import QueryBuilder from "@mui/icons-material/QueryBuilder";
import { Box, Typography, Link, Button } from "@mui/material";
import { isBefore, parseISO } from "date-fns";

import { DayOfWeek, Week } from "utils/Context";
import { If } from "views/atoms";
import { ReactToolTip } from "views/molecules";

/**
 * The function checks if a given date is expired or not.
 * @param inputDate - The inputDate parameter is a string representing a date in ISO format (e.g.
 * "2022-01-01T00:00:00.000Z").
 * @returns The function `isDateExpire` is returning a boolean value indicating whether the input date
 * is before the current date and time (`now`). If the input date is before the current date and time,
 * the function will return `true`, indicating that the date has expired. If the input date is on or
 * after the current date and time, the function will return `false`, indicating that the date has not
 */
const isDateExpire = (inputDate) => {
  const now = new Date();
  const parsedInputDate = parseISO(inputDate);
  return isBefore(parsedInputDate, now);
};

const getCollectCycleTypeNameFromCycle = (cycle) => {
  if (!cycle) {
    return <></>;
  }
  switch (cycle.cycleCategoryId) {
    case "everyDay":
      return cycle.cycleCategoryName;
    case "everyWeek":
      return `${cycle.cycleCategoryName} ${cycle.weekDays
        .map(
          (weekday) => DayOfWeek.find((item) => item.code === weekday).shortName
        )
        .join("・")}`;
    case "everyOtherWeek":
      return `${cycle.cycleCategoryName} ${cycle.weekDays
        .map(
          (weekday) => DayOfWeek.find((item) => item.code === weekday).shortName
        )
        .join("・")} ${cycle.weeks
        .map((week) => Week.find((item) => item.code === week).name)
        .join("・")}`;
    case "everyMonth":
      return `${cycle.cycleCategoryName} ${cycle.weeks
        .map((week) => Week.find((item) => item.code === week).name)
        .join("・")}`;
    case "interval":
      return `${cycle.intervalDays} 日${cycle.cycleCategoryName}`;
    case "customize":
      return ``;
    default:
      return ``;
  }
};

/**
 * 回収場所を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box
    boxShadow={2}
    bgcolor="#fff"
    borderRadius={1}
    p={1}
    mt={2}
    mr={1}
    fontSize="14px"
    position="relative"
  >
    {props.value?.order && (
      <Box bgcolor="text.primary" className={props.classes.box__number}>
        {props.value.order ?? ""}
      </Box>
    )}
    <Box display="flex" alignItems="center">
      <Box
        fontWeight="fontWeightBold"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {props?.value?.wasteGeneratorCompany?.name ?? ""}
      </Box>
    </Box>
    <Box display="flex" alignItems="center">
      <Box
        fontWeight="fontWeightBold"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {props?.value?.wasteCollectionWorkplace?.name ?? ""}
      </Box>
    </Box>
    <Box
      color="text.secondary"
      sx={{
        fontSize: "12px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {`${
        props?.value?.waste?.type?.smallClass?.name ||
        props?.value?.waste?.type?.middleClass?.name ||
        props?.value?.waste?.type?.largeClass?.name ||
        ""
      }`}
    </Box>
    <Box
      color="text.secondary"
      sx={{
        fontSize: "12px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {`${
        props?.value?.waste?.name
          ? `${props?.value?.waste?.name}（${props?.value?.waste?.dischargeAmountPerOneTime}${props?.value?.waste?.quantityUnit?.name}）`
          : `${props?.value?.waste?.dischargeAmountPerOneTime}${props?.value?.waste?.quantityUnit?.name}`
      }`}
    </Box>
    <Box
      color="text.secondary"
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box display="flex" alignItems="center">
        <QueryBuilder
          fontSize="small"
          sx={{ marginRight: (theme) => theme.spacing(1) }}
        />
        <Box>
          <Box
            color="text.secondary"
            sx={{
              fontSize: "11px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >{`${getCollectCycleTypeNameFromCycle(props.value)} `}</Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: "11px",
            }}
          >
            <span>{`${
              props.value?.scheduleTimeRangeStart
                ? props.value?.scheduleTimeRangeStart.substring(0, 5)
                : ""
            } ～ ${
              props.value?.scheduleTimeRangeEnd
                ? props.value?.scheduleTimeRangeEnd.substring(0, 5)
                : ""
            }`}</span>
          </Box>
        </Box>
      </Box>
      <If
        condition={!props.value?.wasteCollectionWorkplace?.position}
        elseComponent={
          <If condition={isDateExpire(props.value.cycleEndDate)}>
            <Button
              sx={{
                cursor: "default",
                position: "absolute",
                height: "22px",
                bottom: "3px",
                right: "6px",
              }}
              size="small"
              variant="outlined"
              color="error"
            >
              終了分
            </Button>
          </If>
        }
      >
        <ReactToolTip
          size="small"
          variant="outlined"
          color="warning"
          title="座標情報未設定"
          sx={{
            zIndex: 10,
            position: "absolute",
            height: "22px",
            bottom: "3px",
            right: "4px",
            px: "3px",
          }}
          placement="right"
        >
          <>
            <Typography textAlign="center">
              事業場の編集から設定することができます。
            </Typography>
            <Typography textAlign="center">
              設定することでマップに表示されます。
            </Typography>
            <br></br>
            <Box textAlign="center">
              <Link
                component="button"
                onClick={() => {
                  props.navigateTo(
                    `/master/partner/${props.value.wasteGeneratorCompany?.id}/workplace/${props.value.wasteCollectionWorkplace?.id}?tab=2`
                  );
                }}
                sx={{
                  cursor: "pointer",
                  padding: 2,
                }}
                textAlign="center"
                underline="always"
              >
                すぐに設定する場合はこちら
              </Link>
            </Box>
            <br></br>
            <Typography textAlign="center">
              ※現在の作業は破棄されます。
            </Typography>
          </>
        </ReactToolTip>
      </If>
    </Box>
  </Box>
);
