import { useState, useEffect, useCallback, useMemo } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  updateConfigurations,
  selector as dashboardWidgetsSelector,
  updateUserDashboardWidgetsDataInBackground,
} from "ducks/Widgets";
import {
  searchUserAccountsByOffset,
  getCarryWorkJournalLocationsByDriver,
} from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { debugLog } from "utils/log";

import { sampleDrivers, driverTaskData } from "./sample";

/**
 * Containerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうか
 * @param {Function} props.render - 子コンポーネントをレンダリングするための関数
 * @param {string} props.widgetId - 表示するウィジェットのID
 */
export const Container = ({ render, widgetId, ...props }) => {
  const [data, setData] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const dispatch = useDispatch();

  const {
    userPreferences: { items: userPreferences },
  } = useSelector(dashboardWidgetsSelector);

  const { configurations } = useMemo(() => {
    return userPreferences.find((widget) => widget.id === widgetId);
  }, [userPreferences, widgetId]);

  useEffect(() => {
    if (!props.editMode && !isEmpty(configurations) && drivers.length) {
      setSelectedDriver(configurations.driverId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations?.driverId, drivers.length]);

  const loadDrivers = useCallback(() => {
    setLoading(true);
    API.graphql(
      graphqlOperation(searchUserAccountsByOffset, {
        state: "active",
      })
    )
      .then((response) => {
        const drivers = response.data.searchUserAccountsByOffset.items;
        setDrivers(drivers);
      })
      .catch((err) => {
        debugLog("ユーザー情報の取得に失敗: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedDriver && !props.editMode) {
      setLoadingData(true);
      API.graphql(
        graphqlOperation(getCarryWorkJournalLocationsByDriver, {
          driverId: selectedDriver,
          date: format(new Date(), "yyyy-MM-dd"),
        })
      )
        .then((response) => {
          const data = response.data.getCarryWorkJournalLocationsByDriver;
          if (data === null) {
            setData([]);
            return;
          }
          let items = [];
          if (data.current) {
            items.push(data.current);
          }
          if (data.next) {
            items.push(data.next);
          }
          setData(items);
        })
        .catch((err) => {
          debugLog("ドライバーのタスク情報の取得に失敗: ", err);
        })
        .finally(() => {
          setLoadingData(false);
        });
    }
  }, [selectedDriver, props.editMode]);

  const sampleDriverTask = useMemo(() => {
    if (!props.editMode) {
      return null;
    }
    if (!selectedDriver) {
      return [];
    }
    return driverTaskData[selectedDriver];
  }, [selectedDriver, props.editMode]);

  useEffect(() => {
    if (props.editMode) {
      setData(sampleDriverTask);
      setDrivers(sampleDrivers);
    } else {
      loadDrivers();
    }
  }, [props.editMode, sampleDriverTask, loadDrivers]);

  useEffect(() => {
    if (props.editMode) {
      setSelectedDriver(sampleDrivers[0].id);
    }
  }, [props.editMode]);

  const handleDriverSelect = (event) => {
    const driverId = event.target.value;
    setSelectedDriver(driverId);
    if (props.editMode) return;

    dispatch(
      updateConfigurations({
        widgetId,
        configurations: {
          driverId,
        },
      })
    );

    const widgets = userPreferences.map((item) => ({
      id: item.id,
      layout: {
        ...item.layout,
        i: undefined,
      },
      configurations:
        item.id === widgetId
          ? JSON.stringify({
              driverId,
            })
          : JSON.stringify(item.configurations),
    }));
    dispatch(updateUserDashboardWidgetsDataInBackground(widgets));
  };

  return render({
    data,
    loading,
    handleDriverSelect,
    selectedDriver,
    drivers,
    loadingData,
    ...props,
  });
};
