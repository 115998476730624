import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import sizeMe from "react-sizeme";
import { Stack, Typography } from "@mui/material";
import { DraggableWrapper } from "views/pages/Dashboard/Components";
import {
  AgreementExpirationAlert,
  ContainerInstallationAlert,
  CoordinateNotRegisterAlert,
  MissedVehicleAlert,
  PermitExpirationAlert,
  UnCollectedAlerts,
  VehicleRemindAlert,
} from "views/pages/Dashboard/Components/Widgets/AlertWidgets";
import {
  TodaysCollectionProgress,
  TodaysCourseStatics,
  WeekCollectedChartsWidget,
  DriversTaskTimeline,
} from "views/pages/Dashboard/Components/Widgets/ChartWidgets";
import {
  ContractStartList,
  RecentAddedAccounts,
  RecentSportCollection,
} from "views/pages/Dashboard/Components/Widgets/ListWidgets";
import { Paper } from "@mui/material";
import { widgetIds } from "utils/DashboardWidgetConstant";

import EmptyStateImage from "assets/svg/EmptyBox.svg";

/**
 * Presententerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.layouts - レイアウトの設定
 * @param {Function} props.onLayoutChange - レイアウト変更時のコールバック関数
 * @param {Function} props.onBreakpointChange - ブレークポイント変更時のコールバック関数
 * @param {Object} props.gridProps - グリッドのプロパティ
 * @param {boolean} props.mounted - コンポーネントがマウントされているかどうか
 * @param {boolean} props.editMode - 編集モードかどうか
 * @param {Function} props.onRemoveFromCanvas - キャンバスからウィジェットを削除する関数
 * @param {boolean} props.openCustomizeDrawer - カスタマイズドロワーが開いているかどうか
 * @param {Object} props.size - サイズのプロパティ
 * @param {Object} props.selectedWidget - 選択されたウィジェット
 * @param {Function} props.setWidgetsRef - ウィジェットの参照を設定する関数
 */
const Presententer = ({
  layouts,
  onLayoutChange,
  onBreakpointChange,
  gridProps,
  mounted,
  editMode,
  onRemoveFromCanvas,
  openCustomizeDrawer,
  size,
  selectedWidget,
  setWidgetsRef,
}) => {
  const components = {
    [widgetIds.COLLECTION_VOLUME_CHART]: (
      <WeekCollectedChartsWidget
        widgetId={widgetIds.COLLECTION_VOLUME_CHART}
        editMode={openCustomizeDrawer}
      />
    ),
    [widgetIds.TODAYS_COLLECTION_PROGRESS]: (
      <TodaysCollectionProgress
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.TODAYS_COLLECTION_PROGRESS}
      />
    ),
    [widgetIds.DRIVERS_TIMELINE]: (
      <DriversTaskTimeline
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.DRIVERS_TIMELINE}
      />
    ),
    [widgetIds.PERMIT_EXPIRATION_ALERTS]: (
      <PermitExpirationAlert
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.PERMIT_EXPIRATION_ALERTS}
      />
    ),
    [widgetIds.AGREEMENT_EXPIRATION_ALERTS]: (
      <AgreementExpirationAlert
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.AGREEMENT_EXPIRATION_ALERTS}
      />
    ),
    [widgetIds.MISSED_VEHICLE_ALERTS]: (
      <MissedVehicleAlert
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.MISSED_VEHICLE_ALERTS}
      />
    ),
    [widgetIds.UNCOLLECTED_ALERTS]: (
      <UnCollectedAlerts
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.UNCOLLECTED_ALERTS}
      />
    ),
    [widgetIds.CONTAINER_INSTALLATION_ALERTS]: (
      <ContainerInstallationAlert
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.CONTAINER_INSTALLATION_ALERTS}
      />
    ),
    [widgetIds.COORDINATE_NOT_REGISTER_ALERTS]: (
      <CoordinateNotRegisterAlert
        widgetId={widgetIds.COORDINATE_NOT_REGISTER_ALERTS}
        editMode={openCustomizeDrawer}
      />
    ),
    [widgetIds.VEHICLE_REMIND_ALERTS]: (
      <VehicleRemindAlert
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.VEHICLE_REMIND_ALERTS}
      />
    ),
    [widgetIds.ALL_COURSE_STATUS]: (
      <TodaysCourseStatics
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.ALL_COURSE_STATUS}
      />
    ),
    [widgetIds.RECENT_CONTRACT_START_LIST]: (
      <ContractStartList
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.RECENT_CONTRACT_START_LIST}
      />
    ),
    [widgetIds.RECENT_ADDED_ACCOUNTS]: (
      <RecentAddedAccounts
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.RECENT_ADDED_ACCOUNTS}
      />
    ),
    [widgetIds.RECENT_ADDED_SPOT_COLLECTIONS]: (
      <RecentSportCollection
        editMode={openCustomizeDrawer}
        widgetId={widgetIds.RECENT_ADDED_SPOT_COLLECTIONS}
      />
    ),
  };

  const dataLayout = layouts.map((layout) => layout.layout);

  const generateDOM = () => {
    return dataLayout.map((layout) => {
      return (
        <Paper
          key={layout.i}
          component="div"
          id={layout.i}
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            height: "100%",
            width: "100%",
            position: "relative",
            pt: editMode ? 5 : 0,
            ...(selectedWidget === layout.i && {
              animation: "bgHighlight 0.5s ease",
            }),
            "@keyframes bgHighlight": {
              "0%": { backgroundColor: "#F0F0F0" },
              "50%": { backgroundColor: "#FFF3CD" },
              "100%": { backgroundColor: "#F0F0F0" },
            },
          }}
          data-grid={layout}
        >
          <DraggableWrapper
            editMode={editMode}
            handleClose={() => onRemoveFromCanvas(layout.i)}
            widgetName={layout.name}
          >
            {components[layout.i]}
          </DraggableWrapper>
        </Paper>
      );
    });
  };

  if (dataLayout.length === 0 && editMode) {
    return (
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={500}
      >
        <img width={150} src={EmptyStateImage} alt="empty-dashboard" />
        <Typography variant="h6" mt={2} fontWeight={700} color="textSecondary">
          ウィジェットがありません
        </Typography>
        <Typography variant="body1" color="textSecondary">
          左側のウィジェットリストからウィジェットを選択してください。
        </Typography>
      </Stack>
    );
  }

  return (
    <ResponsiveGridLayout
      onLayoutChange={onLayoutChange}
      onBreakpointChange={onBreakpointChange}
      layouts={dataLayout}
      measureBeforeMount={false}
      useCSSTransforms={!!mounted}
      innerRef={setWidgetsRef}
      breakpoints={{ lg: 1200, sm: 600, xs: 480, xxs: 0 }}
      cols={{ lg: 24, sm: 20, xs: 8, xxs: 4 }}
      rowHeight={2}
      margin={[30, 30]}
      width={size.width}
      {...gridProps}
      draggableHandle=".drag-handle"
    >
      {generateDOM()}
    </ResponsiveGridLayout>
  );
};

export default sizeMe({
  refreshMode: "debounce",
  refreshRate: 60,
})(Presententer);
