import { useNavigate } from "react-router-dom";
import { searchCarryWorkJournalsByOffset } from "api/graphql/queries";
import { formatDisplayDate } from "utils/format";
import { useXGridComponents } from "utils/useXGridComponents";

const columns = [
  {
    field: "departureDate",
    headerName: "回収日",
    width: 150,
    renderCell: (params) =>
      formatDisplayDate({
        source: params.value,
        destFormat: "yyyy/MM/dd (EEE)",
      }),
  },
  {
    field: "name",
    headerName: "コース名",
    flex: 1,
  },
  {
    field: "taskSkippedCount",
    headerName: "スキップされた回収",
    flex: 1,
  },
];

/**
 *
 * @param {Object} props - コンポーネントに渡されるプロパティ
 * @param {Function} props.render - レンダリング関数
 * @param {...Object} props - その他のプロパティ
 *
 * @returns {JSX.Element}
 *
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const obj = useXGridComponents(
    columns,
    searchCarryWorkJournalsByOffset,
    {
      other: {
        filter: {
          skippedOnly: {
            eq: true,
          },
        },
      },
    },
    {
      pageSize: 10,
    }
  );

  const handleSelected = (params) => {
    const row = obj.params.rows
      .map((d, i) => ({ id: i, ...d }))
      .find((i) => i.id === params[0]);
    navigate(`/collect/${row.id}`);
  };

  return render({
    xGridParams: obj.params,
    onSelected: handleSelected,
    ...props,
  });
};
