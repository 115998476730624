import { useState } from "react";
import { XGridComponent } from "views/molecules";
import { useXGridComponents } from "utils/useXGridComponents";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import {
  searchContractsByOffset,
  getContract,
  searchClientsByOffset,
} from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";
import { API, graphqlOperation } from "utils/graphqlOperation";

const columns = [
  {
    field: "number",
    headerName: "管理番号",
    width: 150,
  },
  {
    field: "name",
    headerName: "契約書名",
    width: 250,
  },
  {
    field: "parties A",
    headerName: "契約者（甲）",
    width: 250,
    renderCell: (params) => {
      return params.row.partyCompanies?.[0].name ?? "";
    },
    filterable: false,
    sortable: false,
  },
  {
    field: "parties B",
    headerName: "契約者（乙）",
    width: 250,
    renderCell: (params) => {
      return params.row.partyCompanies?.[1].name ?? "";
    },
    filterable: false,
    sortable: false,
  },
  {
    field: "executedOn",
    headerName: "契約日",
    width: 150,
    valueFormatter: (params) =>
      params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "",
  },
  {
    field: "termOfAgreementStartedOn",
    headerName: "契約期間開始日",
    width: 180,
    valueFormatter: (params) =>
      params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "",
  },
  {
    field: "termOfAgreementExpirationOn",
    headerName: "契約期間終了日",
    width: 180,
    valueFormatter: (params) =>
      params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "",
  },
  {
    field: "lastUpdatedAt",
    headerName: "最終更新日時",
    width: 200,
    valueFormatter: (params) =>
      params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm:ss"
          )
        : "",
  },
];

const transiteContractPage = (supplierCompanyId, contractId, navigate) => {
  if (!contractId) {
    return;
  }

  API.graphql(
    graphqlOperation(getContract, {
      id: contractId,
    })
  ).then((contract) => {
    if (contract.data.getContract.type.name === "二者契約") {
      const targetCompany = contract.data.getContract.partyCompanies.find(
        (c) => c.id !== contract.data.getContract.ownerCompanyId
      );

      API.graphql(
        graphqlOperation(searchClientsByOffset, {
          supplierCompanyId: supplierCompanyId,
        })
      ).then((clients) => {
        navigate({
          pathname: `/master/partner/${
            clients.data.searchClientsByOffset.items.find(
              (item) => item.companyId === targetCompany.id
            ).id
          }/contract/${contract.data.getContract.id}`,
          search: "?alert",
        });
      });
    }
  });
};

/**
 * 契約書アラート一覧を表示するコンポーネントです。
 * @returns {JSX.Element}
 */
export const List = () => {
  const [redirectLoading, setRedirectLoading] = useState(false);
  const navigate = useNavigate();
  const company = useSelector(companySelector);

  const obj = useXGridComponents(
    columns,
    searchContractsByOffset,
    {
      other: {
        isFilteredForAlerts: true,
      },
    },
    {
      pageSize: 10,
    }
  );

  const handleSelected = (params) => {
    setRedirectLoading(true);
    transiteContractPage(company.id, params[0], navigate);
  };

  return (
    <XGridComponent
      onChangeSelection={handleSelected}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={false}
      onFilter={false}
      onColumns={false}
      {...obj?.params}
      loading={obj.params?.loading || redirectLoading}
    />
  );
};
