import React from "react";
import { Box } from "@mui/material";
import { LoadingSkeleton } from "views/pages/Dashboard/Components";
import { AlertBox } from "views/pages/Dashboard/Components/Widgets/AlertWidgets";
import { UnCollectedList } from "views/pages/Dashboard/Components/Widgets/AlertWidgets/UnCollectedAlert/UnCollectedList";

/**
 * Presententerコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.loading - ローディング状態かどうか
 * @param {number} props.value - 表示する値
 * @param {string} props.name - アラートの名前
 * @param {Function} props.handleClick - クリックイベントを処理する関数
 */
export const Presententer = ({ loading, value, name, dialog, handleClick }) => {
  if (loading) return <LoadingSkeleton loading size={400} />;

  return (
    <>
      <Box
        sx={{
          p: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <AlertBox onClick={handleClick} title={name} total={value} />
        {dialog.open && <UnCollectedList dialog={dialog} />}
      </Box>
    </>
  );
};
