import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { join, map } from 'lodash';
import { LoadingSkeleton } from "views/pages/Dashboard/Components";
import { format } from "date-fns";

const columns = [
  {
    field: "establishmentedOn", headerName: "登録日", flex: 1,
    renderCell: (params) => {
      return format(new Date(params.row.establishmentedOn), "yyyy/MM/dd");
    }
  },
  {
    field: "businessType", headerName: "事業者区分", flex: 1, renderCell: (params) => {
      const businessCategories = params.row.companyCategories;
      return join(map(businessCategories, 'name'), '、');
    }
  },
  { field: "name", headerName: "取引先名", flex: 1 },
];

/**
 * Presententerコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.loading - ローディング状態かどうか
 * @param {Array} props.data - 表示するデータの配列
 * @param {Function} props.handleMoreData - もっと見るボタンのクリック時のコールバック関数
 * @param {string} props.name - アラートの名前
 * @param {Function} props.onCellClick - セルクリック時のコールバック関数
 */
export const Presententer = ({ loading, data, handleMoreData, name, onCellClick }) => {
  if (loading) return <LoadingSkeleton loading size={300} />;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        px: 1.5,
        pt: 2,
      }}
    >
      <Typography
        sx={(theme) => ({
          height: theme.spacing(5),
        })}
        color="text.secondary"
        fontWeight="bold"
        textAlign="left"
      >
        {name}
      </Typography>
      <Box
        sx={(theme) => ({
          width: "100%",
          height: `calc(100% - ${theme.spacing(11)})`,
        })}
      >
        <DataGridPro onCellClick={(params) => onCellClick(params.row.id)} rowHeight={40} columns={columns} rows={data} />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "center",
          height: theme.spacing(5),
        })}
      >
        <Button
          onClick={handleMoreData}
          sx={{
            textAlign: "center",
            mt: 1,
          }}
        >
          もっと見る <ExpandMoreIcon />
        </Button>
      </Box>
    </Box>
  );
};
