import { API, graphqlOperation } from "utils/graphqlOperation";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDashboardWidgets,
  getUserDashboardWidgets,
} from "api/graphql/queries";

import { manageUserDashboardWidgets } from "api/graphql/mutations";

const getDashboardWidgetsList = async () => {
  return await API.graphql(graphqlOperation(getDashboardWidgets));
};

const getUserPreferenceDashboardWidgets = async () => {
  return await API.graphql(graphqlOperation(getUserDashboardWidgets));
};

const updateUserPreferenceDashboardWidgets = async (widgets) => {
  return await API.graphql(
    graphqlOperation(manageUserDashboardWidgets, { input: { widgets } })
  );
};

export const getDashboardWidgetsData = createAsyncThunk(
  "dashboardWidgets/getDashboardWidgetsData",
  async (_, thunkAPI) => {
    try {
      const result = await getDashboardWidgetsList();
      return result.data.getDashboardWidgets;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const getUserDashboardWidgetsData = createAsyncThunk(
  "dashboardWidgets/getUserDashboardWidgetsData",
  async (_, thunkAPI) => {
    try {
      const result = await getUserPreferenceDashboardWidgets();
      return result.data.getUserDashboardWidgets;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const updateUserDashboardWidgetsData = createAsyncThunk(
  "dashboardWidgets/updateUserDashboardWidgetsData",
  async (widgets, thunkAPI) => {
    try {
      const result = await updateUserPreferenceDashboardWidgets(widgets);
      return result.data.manageUserDashboardWidgets;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const updateUserDashboardWidgetsDataInBackground = createAsyncThunk(
  "dashboardWidgets/updateUserDashboardWidgetsDataInBackground",
  async (widgets, thunkAPI) => {
    try {
      const result = await updateUserPreferenceDashboardWidgets(widgets);
      return result.data.manageUserDashboardWidgets;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);


