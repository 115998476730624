/**
 * Containerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.loading - ローディング状態
 * @param {string} props.bgcolor - 背景色
 */
export const Container = ({ render, ...props }) => {
  return render({
    ...props,
  });
};
