import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LoadingSkeleton } from "views/pages/Dashboard/Components";
import { convertUtcToJst } from "utils/format";
import { format } from "date-fns";

const columns = [
  {
    field: "createdAt",
    headerName: "登録日",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {
      const createdAt = params.row.createdAt;
      return convertUtcToJst({ utcDate: createdAt });
    },
  },
  {
    field: "scheduleDate",
    headerName: "回収日",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {
      const scheduleDate = params.row.scheduleDate;
      return `${format(
        new Date(scheduleDate),
        "yyyy/MM/dd"
      )} (${params.row.scheduleTimeRangeStart.slice(
        0,
        5
      )} - ${params.row.scheduleTimeRangeEnd.slice(0, 5)})`;
    },
  },
  {
    field: "wasteGeneratorCompanyName",
    headerName: "排出事業者",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "wasteCollectionWorkplaceName",
    headerName: "排出事業場",
    minWidth: 200,
    flex: 1,
  },
];

/**
 * Presententerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.loading - ローディング状態かどうか
 * @param {Array} props.data - 表示するデータの配列
 * @param {Function} props.handleMoreData - もっと見るボタンのクリック時のコールバック関数
 * @param {string} props.name - アラートの名前
 * @param {Function} props.onCellClick - セルクリック時のコールバック関数
 * @param {string} props.scheduleTimeRangeStart - スケジュールの開始時間範囲
 */
export const Presententer = ({
  loading,
  data,
  name,
  handleMoreData,
  onCellClick,
}) => {
  if (loading) return <LoadingSkeleton loading size={300} />;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        pt: 2,
        px: 1.5,
      }}
    >
      <Typography
        sx={(theme) => ({
          height: theme.spacing(4),
        })}
        color="text.secondary"
        fontWeight={800}
        textAlign="left"
      >
        {name}
      </Typography>
      <Box
        sx={(theme) => ({
          width: "100%",
          height: `calc(100% - ${theme.spacing(11)})`,
          mt: 1,
        })}
      >
        <DataGridPro
          onCellClick={(params) => {
            onCellClick(params.row.id);
          }}
          rowHeight={40}
          columns={columns}
          rows={data}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "center",
          height: theme.spacing(5),
          pd: 1,
        })}
      >
        <Button
          onClick={handleMoreData}
          sx={{
            textAlign: "center",
            mt: 1,
          }}
        >
          もっと見る <ExpandMoreIcon />
        </Button>
      </Box>
    </Box>
  );
};
