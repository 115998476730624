import { Box, Typography } from "@mui/material";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Sector,
  Tooltip,
} from "recharts";

import { LoadingSkeleton } from "views/pages/Dashboard/Components/LoadingSkeleton";

const STATUS = [
  { color: "#3949AB", name: "回収完了" },
  { color: "#8896E3", name: "スキップされた回収" },
  { color: "#DCDCF5", name: "未回収" },
];

/**
 * カスタムツールチップコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.active - ツールチップがアクティブかどうか
 * @param {Array} props.payload - ツールチップに表示するデータの配列
 * @returns {JSX.Element|null} ツールチップのJSX要素、またはnull
 */
const CustomTooltip = (props) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <Box
        borderRadius={2}
        px={2}
        py={1}
        bgcolor="#FAFAFA"
        border="1px solid"
        width="fit-content"
      >
        <Typography
          fontSize={12}
          fontWeight="bold"
        >{`${payload[0].name} : ${payload[0].value}`}</Typography>
      </Box>
    );
  }
  return null;
};

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {object[]} props.data - データ。
 * @param {function} props.onMouseEnter - マウスが入ったときのコールバック関数。
 * @param {number} props.activeIndex - アクティブなインデックス。
 * @param {function} props.onMouseLeave - マウスが離れたときのコールバック関数。
 * @param {number} props.total - 合計値。
 * @param {boolean} props.loading - ローディング状態。
 * @returns {JSX.Element} タスクステータスコンポーネント。
 */
export const Presententer = ({
  tasks,
  name,
  onMouseEnter,
  activeIndex,
  onMouseLeave,
  total,
  loading,
}) => {
  if (loading) return <LoadingSkeleton loading />;

  return (
    <Box
      sx={{
        p: 1,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          gap: 3,
          height: `calc(100% - ${theme.spacing(5)})`,
        })}
      >
        <Box width="fit-content">
          <Typography
            sx={(theme) => ({
              height: theme.spacing(5),
            })}
            color="text.secondary"
            textAlign="start"
            fontWeight="bold"
          >
            {name}
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: 20,
              fontWeight: 800,
            }}
          >
            <Typography
              fontSize={30}
              color="#3B86FF"
              fontWeight={800}
              component="span"
            >
              {total.completed || 0}
            </Typography>
            件 / {total.total} 件
          </Typography>

          {STATUS.map((item) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                py: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: item.color,
                  width: 20,
                  height: 20,
                }}
              />
              <Typography
                sx={{
                  color: "text.secondary",
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            flex: 1,
            mt: 3,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={tasks}
                innerRadius="70%"
                outerRadius="90%"
                cx="50%"
                cy="50%"
                fill="#8884d8"
                dataKey="value"
                activeShape={(props) => {
                  const {
                    cx,
                    cy,
                    innerRadius,
                    outerRadius,
                    startAngle,
                    endAngle,
                    fill,
                    value,
                  } = props;
                  return (
                    <g>
                      <text
                        x={cx}
                        y={cy}
                        dy={5}
                        fontSize={20}
                        fontWeight={700}
                        textAnchor="middle"
                        fill="#3B86FF"
                      >
                        {value}
                      </text>
                      <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                      />
                    </g>
                  );
                }}
              >
                {tasks.map((item, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={STATUS[index % STATUS.length].color}
                    strokeWidth={activeIndex === index ? 1 : 0}
                    onMouseEnter={() => onMouseEnter(item, index)}
                    onMouseLeave={onMouseLeave}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  );
};
