import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * コンテナインストールアラートを表示するためのコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.widgetId - 表示するウィジェットのID
 * @param {boolean} props.editMode - コンポーネントが編集モードかどうかを示すフラグ
 */
export const ContainerInstallationAlert = containerPresententer(
  Container,
  Presententer
);
