import Skeleton from "@mui/material/Skeleton";

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {boolean} props.loading - ローディング状態。
 * @param {string} props.bgcolor - 背景色。
 * @returns {JSX.Element} カスタマイズアイコンコンポーネント。
 */

export const Presententer = ({
  loading,
  variant = "rectangular",
  bgcolor = "grey.400",
}) => {
  if (!loading) return null;
  return (
    <Skeleton
      sx={{
        bgcolor: bgcolor,
        borderRadius: 1,
        width: "100%",
        height: "100%",
      }}
      variant={variant}
    ></Skeleton>
  );
};
