import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 契約を表示するコンポーネントです
 * @param {object} props プロパティ
 * @fires ContractTable#onSelected 選択された時
 * @fires ContractTable#onSubmitted 送信された時
 * @returns {JSX.Element}
 */
export const ContractTable = containerPresententer(Container, Presententer);
