import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * RecentAddedAccountsコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうか
 * @param {Function} props.render - 子コンポーネントをレンダリングするための関数
 * @param {string} props.widgetId - 表示するウィジェットのID
 */
export const RecentAddedAccounts = containerPresententer(
  Container,
  Presententer
);
