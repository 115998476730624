import { useNavigate } from "react-router-dom";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/*
 * object shape
 *   createdAt
 *   id
 *   order
 *   scheduleDate
 *   scheduleTimeRangeEnd
 *   scheduleTimeRangeStart
 *   updatedAt
 *   version
 *   wasteCarrierCompany
 *     id
 *     name
 *     phoneticGuidesOfName
 *   wasteCollectionWorkplace
 *     city
 *     email
 *     fax
 *     id
 *     name
 *     otherAddress
 *     phone
 *     phoneticGuidesOfName
 *     postalCode
 *     prefectures
 *       code
 *       name
 *       removedAt
 *   wasteGeneratorCompany
 *     id
 *     name
 *     phoneticGuidesOfName
 *   wasteId
 *   wasteName
 *   wasteQuantity
 *   wasteQuantityUnitName
 */

/**
 * 回収場所を表示するコンポーネントです。
 * @param {object} value 回収場所情報
 * @returns
 */
export const Container = ({ render, value, nextDay, ...props }) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };
  return render({
    value: value,
    classes: classes,
    nextDay: nextDay,
    navigateTo: navigateTo,
    ...props,
  });
};
