/**
 * @param {object} props - プロパティオブジェクト。
 * @param {React.ReactNode} props.children - 子要素。
 * @param {object} props.provided - Draggableコンポーネントから提供されるプロパティ。
 * @param {number} props.xs - Gridのxsプロパティ。
 * @returns {JSX.Element} ドラッグ可能なカードコンポーネント。
 */
export const Container = ({ render, ...props }) => {
  return render({
    ...props,
  });
};
