import { useState, Fragment } from "react";
import { Box, Typography, Chip, Paper } from "@mui/material";
import { PieChart, Pie, Sector, Cell } from "recharts";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import { LoadingSkeleton } from "views/pages/Dashboard/Components";

const COLORS = ["#3949AB", "#8896E3", "#DCDCF5"];

/**
 * CourseCardコンポーネントは、コースの詳細と円グラフを表示するカードを表示します。
 *
 * @param {Object} props - コンポーネントのプロパティ。
 * @param {Object} props.course - 詳細を含むコースオブジェクト。
 * @param {Array} props.course.data - 円グラフのデータ。
 * @param {Object} props.course.assignedVehicle - コースに割り当てられた車両。
 * @param {Array} props.course.assignedUsers - コースに割り当てられたユーザー。
 * @param {string} props.course.name - コースの名前。
 * @param {number} props.course.taskTotal - コースのタスクの総数。
 * @param {string} props.course.id - コースのID。
 * @param {Function} props.navigateTo - 別のルートに移動するための関数。
 *
 * @returns {JSX.Element} レンダリングされたCourseCardコンポーネント。
 */

const TEMP_DEFAULT_VALUE_WHEN_ZERO = 0.01;
const CourseCard = ({
  course: { data, assignedVehicle, assignedUsers, name, taskTotal, id },
  navigateTo,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = () => navigateTo(`/collect/${id}`);

  return (
    <Paper
      onClick={handleClick}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 2,
        px: 1,
        py: 1,
        height: 80,
        cursor: "pointer",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={80}
        height="100%"
      >
        {taskTotal ? (
          <PieChart width={70} height={70}>
            <Pie
              activeIndex={activeIndex}
              onPointerEnter={(_, index) => setActiveIndex(index)}
              onPointerLeave={() => setActiveIndex(0)}
              activeShape={(props) => {
                const {
                  cx,
                  cy,
                  innerRadius,
                  outerRadius,
                  startAngle,
                  endAngle,
                  fill,
                } = props;
                return (
                  <g>
                    <text
                      x={cx}
                      y={cy}
                      dy={5}
                      fontSize={15}
                      fontWeight={700}
                      textAnchor="middle"
                      fill="#3B86FF"
                    >
                      {data[activeIndex]?.value === TEMP_DEFAULT_VALUE_WHEN_ZERO
                        ? "0"
                        : data[activeIndex]?.value}
                    </text>
                    <Sector
                      cx={cx}
                      cy={cy}
                      innerRadius={innerRadius}
                      outerRadius={outerRadius}
                      startAngle={startAngle}
                      endAngle={endAngle}
                      fill={fill}
                    />
                  </g>
                );
              }}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={20}
              outerRadius={30}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((i, index) => (
                <Cell
                  key={`cell-${i.name}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        ) : (
          <Box
            width={60}
            height={60}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
            ml={1}
            bgcolor={(theme) => theme.palette.text.secondary}
          >
            <Typography variant="caption" color="wheat">
              未設定
            </Typography>
          </Box>
        )}
      </Box>
      <Box flex={1}>
        <Typography
          fontWeight="bold"
          variant="body2"
          color="text.secondary"
          gutterBottom
        >
          {name}
        </Typography>
        <Box display="flex" gap={1} overflowX="auto">
          {assignedVehicle ? (
            <Chip
              size="small"
              avatar={<LocalShippingOutlinedIcon style={{ color: "#fff" }} />}
              sx={{
                backgroundColor: "#3B86FF",
                color: "#fff",
              }}
              label={assignedVehicle.number}
            />
          ) : (
            <Chip
              size="small"
              avatar={<LocalShippingOutlinedIcon style={{ color: "#fff" }} />}
              sx={(theme) => ({
                backgroundColor: theme.palette.error.main,
                color: "#fff",
              })}
              label="未設定"
            />
          )}
          {assignedUsers.length === 0 && (
            <Chip
              size="small"
              avatar={<PersonOutlineOutlinedIcon style={{ color: "#fff" }} />}
              sx={(theme) => ({
                backgroundColor: theme.palette.error.main,
                color: "#fff",
              })}
              label="未設定"
            />
          )}
          {assignedUsers.map((user, index) => (
            <Chip
              key={index}
              size="small"
              avatar={<PersonOutlineOutlinedIcon style={{ color: "#fff" }} />}
              sx={{
                backgroundColor: "#2E7D32",
                color: "#fff",
              }}
              label={user.name}
            />
          ))}
        </Box>
      </Box>
      <Box width={200} display="flex" justifyContent="end" alignItems="center">
        <Typography
          variant="h6"
          color="text.secondary"
          fontWeight={700}
          gutterBottom
        >
          {data[0]?.value === TEMP_DEFAULT_VALUE_WHEN_ZERO ? 0 : data[0]?.value}
          /{taskTotal}
        </Typography>
      </Box>
    </Paper>
  );
};

/**
 * Presententerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.courses - コースのリスト
 * @param {boolean} props.loading - ローディング状態
 * @param {string} props.name - 表示する名前
 * @param {Function} props.navigateTo - ナビゲーション関数
 * @returns {JSX.Element} Presententerコンポーネント
 */
export const Presententer = ({ courses, loading, name, navigateTo }) => {
  if (loading) return <LoadingSkeleton size={300} loading />;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        px: 2,
        py: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          sx={(theme) => ({
            height: theme.spacing(5),
            ml: theme.spacing(1),
          })}
          color="text.secondary"
          textAlign="start"
          fontWeight="bold"
        >
          {name}
        </Typography>
        <Typography
          fontSize={12}
          color="text.secondary"
          textAlign="end"
          fontWeight="bold"
        >
          本日のコース:{" "}
          {new Date().toLocaleString("ja-JP", { dateStyle: "medium" })}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: `calc(100% - ${theme.spacing(5)})`,
          overflowY: "auto",
          px: 1,
          py: 1,
        })}
      >
        {courses.length === 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography color="text.secondary" textAlign="center">
              本日のコースはありません
            </Typography>
          </Box>
        )}
        {courses?.map((course) => (
          <Fragment key={course.id}>
            <CourseCard course={course} navigateTo={navigateTo} />
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};
