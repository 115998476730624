import { createSlice } from "@reduxjs/toolkit";
import {
  getDashboardWidgetsData,
  getUserDashboardWidgetsData,
  updateUserDashboardWidgetsData,
} from "./api";

const DashboardWidgets = createSlice({
  name: "dashboardWidgets",
  initialState: {
    loading: false,
    error: null,
    widgets: {
      loading: false,
      error: null,
      items: [],
    },
    userPreferences: {
      loading: false,
      error: null,
      items: [],
      lastFetchedItems: [],
    },
  },
  reducers: {
    addWidgetToUserPreference: (state, action) => {
      const widgetId = action.payload.widgetId;
      const configurations = action.payload.configurations;
      let widget = state.widgets.items[widgetId];
      if (!widget) return;
      widget = {
        ...widget,
        configurations,
      };
      const item = {
        id: widgetId,
        layout: {
          ...widget.layout,
          i: widgetId,
          isResizable: true,
        },
        configurations: configurations,
      };
      if (!state.userPreferences.items.length) {
        state.userPreferences.items = [item];
        return;
      }
      state.userPreferences.items.unshift(item);
    },
    removeWidgetFromUserPreference: (state, action) => {
      const widgetId = action.payload;
      state.userPreferences.items = state.userPreferences.items.filter(
        (widget) => widget.id !== widgetId
      );
    },
    resetAllWidgets: (state, action) => {
      const layouts = action.payload;
      state.userPreferences.items = layouts.map((layout) => {
        return {
          id: layout.i,
          layout: layout,
          configurations: state.userPreferences.items.find(
            (widget) => widget.id === layout.i
          ).configurations,
        };
      });
    },
    addWidgets: (state, action) => {
      const newWidgets = action.payload.filter((widget) =>
        state.widgets.some((initialWidget) => initialWidget.id === widget.id)
      );
      state.widgets.push(...newWidgets);
    },
    removeWidget: (state, action) => {
      state.widgets = state.widgets.filter(
        (widget) => widget.id !== action.payload
      );
    },
    makeAllWidgetResizable: (state, _) => {
      state.userPreferences.items = state.userPreferences.items.map(
        (widget) => {
          return {
            ...widget,
            layout: {
              ...widget.layout,
              isResizable: true,
            },
          };
        }
      );
      state.userPreferences.lastFetchedItems =
        state.userPreferences.lastFetchedItems.map((widget) => {
          return {
            ...widget,
            layout: {
              ...widget.layout,
              isResizable: true,
            },
          };
        });
    },
    makeAllWidgetUnresizable: (state) => {
      state.userPreferences.items = state.userPreferences.items.map(
        (widget) => {
          return {
            ...widget,
            layout: {
              ...widget.layout,
              isResizable: false,
            },
          };
        }
      );
      state.userPreferences.lastFetchedItems =
        state.userPreferences.lastFetchedItems.map((widget) => {
          return {
            ...widget,
            layout: {
              ...widget.layout,
              isResizable: false,
            },
          };
        });
    },
    discardLayoutChange: (state, _) => {
      state.userPreferences.items = state.userPreferences.lastFetchedItems;
    },
    updateConfigurations: (state, action) => {
      const { widgetId, configurations } = action.payload;
      const index = state.userPreferences.items.findIndex(
        (item) => item.id === widgetId
      );
      if (index === -1) return;
      state.userPreferences.items[index].configurations = configurations;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardWidgetsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardWidgetsData.fulfilled, (state, action) => {
        state.loading = false;
        state.widgets.items = action.payload.items.reduce((acc, obj) => {
          acc[obj.id] = {
            ...obj,
            layout: {
              ...obj.layout,
              i: obj.id,
            },
          };
          return acc;
        }, {});
      })
      .addCase(getDashboardWidgetsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getUserDashboardWidgetsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDashboardWidgetsData.fulfilled, (state, action) => {
        const payloadItems = action.payload.items.map((item) => ({
          ...item,
          layout: {
            ...item.layout,
            i: item.id,
            isResizable: false,
          },
          configurations: item.configurations
            ? JSON.parse(item.configurations)
            : null,
        }));
        state.loading = false;
        state.userPreferences.items = payloadItems;
        state.userPreferences.lastFetchedItems = payloadItems;
      })
      .addCase(getUserDashboardWidgetsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(updateUserDashboardWidgetsData.pending, (state) => {
        state.userPreferences.loading = true;
      })
      .addCase(updateUserDashboardWidgetsData.fulfilled, (state, action) => {
        state.loading = false;
        const items = action.payload.items.map((item) => ({
          ...item,
          layout: {
            ...item.layout,
            i: item.id,
            isResizable: false,
          },
          configurations: item.configurations
            ? JSON.parse(item.configurations)
            : null,
        }));
        state.userPreferences.items = items;
        state.userPreferences.lastFetchedItems = items;
      })
      .addCase(updateUserDashboardWidgetsData.rejected, (state, action) => {
        state.loading = false;
        state.userPreferences.error = action.error;
      });
  },
});

export const {
  addWidgetToUserPreference,
  removeWidgetFromUserPreference,
  addWidgets,
  removeWidget,
  resetAllWidgets,
  makeAllWidgetResizable,
  makeAllWidgetUnresizable,
  discardLayoutChange,
  updateConfigurations,
} = DashboardWidgets.actions;
export const selector = (store) => store.DashboardWidgets;
export default DashboardWidgets.reducer;
