import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * このコンポーネントは未配達の車両のデータをデータグリッドに表示します。
 * @param {Object} props.dialog - ダイアログの状態オブジェクト。
 * @param {boolean} props.dialog.open - ダイアログが開いているかどうかを示します。
 * @param {Function} props.dialog.toggle - ダイアログの状態を切り替える関数。
 * @returns {JSX.Element}
 */
export const CoordinateNotRegisterWorkPlaceList = containerPresententer(
  Container,
  Presententer
);
