import { useSelector, useDispatch } from "react-redux";
import {
  selector as dashboardWidgets,
  addWidgetToUserPreference,
  removeWidgetFromUserPreference,
} from "ducks/Widgets";

import { getDefaultWidgetConfiguration } from "utils/DashboardWidgetConstant";

/**
 * Containerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.open - ドロワーが開いているかどうか
 * @param {number} props.DRAWERWIDTH - ドロワーの幅
 * @param {Function} props.handleWidgetSelect - ウィジェット選択を処理する関数
 * @param {Function} props.onChangeWidgetStatus - ウィジェットのステータスを変更する関数
 */
export const Container = ({ render, ...props }) => {
  const { userPreferences, widgets } = useSelector(dashboardWidgets);
  const dispatch = useDispatch();

  const onChangeWidgetStatus = (checked, widgetId) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (checked) {
      dispatch(
        addWidgetToUserPreference({
          widgetId,
          configurations: getDefaultWidgetConfiguration(widgetId),
        })
      );
    } else {
      dispatch(removeWidgetFromUserPreference(widgetId));
    }
  };

  return render({
    userPreferences,
    onChangeWidgetStatus,
    widgets,
    ...props,
  });
};
