import yup, { dateSchema } from "utils/yup";
import { modeList } from "utils/Context";

export const basicSchema = yup
  .object()
  .shape({
    aggregateOn: yup
      .object()
      .shape({
        start: dateSchema.required(),
        end: dateSchema.required(),
      })
      .test(
        "contradiction",
        "開始日より後の日付を入力してください",
        function (value) {
          if (!value?.start || !value?.end) {
            return true;
          }
          value.start.setHours(0, 0, 0, 0);
          value.end.setHours(0, 0, 0, 0);
          return value.start <= value.end;
        }
      )
      .test("range-validation", function (value) {
        if (!value.mode) {
          return true;
        }
      })
      .required(),
    mode: yup.string().required(),
    method: yup.string().required(),
    dataset: yup.string().required(),
    dateType: yup.string().required(),
    groupColumns: yup
      .object()
      .shape({
        logisticsCourseName: yup.boolean().required(),
        wasteGeneratorCompanyName: yup.boolean().required(),
        wasteCollectionWorkplaceName: yup.boolean().required(),
        wasteCollectionWorkplacePrefecture: yup.boolean().required(),
        wasteCollectionWorkplaceCity: yup.boolean().required(),
        wasteLargeClassName: yup.boolean().required(),
        wasteMiddleClassName: yup.boolean().required(),
        wasteSmallClassName: yup.boolean().required(),
        wasteKindName: yup.boolean().required(),
        wasteName: yup.boolean().required(),
        wasteDisposalCompanyName: yup.boolean().required(),
        wasteDisposalWorkplaceName: yup.boolean().required(),
        wasteDisposalWorkplacePrefecture: yup.boolean().required(),
        wasteDisposalWorkplaceCity: yup.boolean().required(),
        wasteDisposalMethodLargeClassName: yup.boolean().required(),
        wasteDisposalMethodMiddleClassName: yup.boolean().required(),
        wasteDisposalMethodSmallClassName: yup.boolean().required(),
        wasteDisposalMethodDetails: yup.boolean().required(),
        wasteQuantityUnitName: yup.boolean().required(),
      })
      .required(),
  })
  .test("date-range-validation", function (value) {
    const {
      aggregateOn: { start, end },
      mode,
    } = value;
    if (!mode) {
      return true;
    }

    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffInMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());
    const diffInYears = endDate.getFullYear() - startDate.getFullYear();
    switch (mode) {
      case modeList[0].id:
        if (diffInMonths > 2) {
          return this.createError({
            message: "毎日モードでは 2 か月のみ選択できます。",
            path: "aggregateOn",
          });
        }
        return true;
      case modeList[1].id:
        if (diffInMonths > 12) {
          return this.createError({
            message: "毎週モードでは 12 か月のみ選択できます。",
            path: "aggregateOn",
          });
        }
        return true;
      case modeList[2].id:
        if (diffInYears > 3) {
          return this.createError({
            message: "毎月モードでは 3 年のみ選択できます。",
            path: "aggregateOn",
          });
        }
        return true;
      case modeList[3].id:
        if (diffInYears > 5) {
          return this.createError({
            message: "毎年モードでは 5 年のみ選択できます。",
            path: "aggregateOn",
          });
        }
        return true;
      case modeList[4].id:
        if (diffInYears > 5) {
          return this.createError({
            message: "指定日モードでは 5 年の間のみ選択できます。",
            path: "aggregateOn",
          });
        }
        return true;
      default:
        return true;
    }
  });
