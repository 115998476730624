/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://hq54osjg75dipa5ml4ycjsk7ye.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:81861478-20eb-4416-969c-14dfbb1c58b6",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_sspszJ6mz",
    "aws_user_pools_web_client_id": "69314cpu7utmfqrukvqe56m82m",
    "oauth": {},
};


export default awsmobile;
