import { getUserAccount } from "api/graphql/queries";
import { useParams } from "react-router-dom";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";
import { userSelector } from "ducks/User";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserAccount } from "api/graphql/mutations";

/**
 * メンバー管理詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector(userSelector);
  const form = useOpener();
  const ownership = useOpener();
  const member = useQuery({
    query: getUserAccount,
    variables: {
      id: id,
    },
    onCompleted: (params) => {},
  });

  if (currentUser?.roleName === "一般ユーザー") {
    navigate({
      pathname: "/",
    });
  }

  const [updateMutation, updateMutationState] = useMutation(updateUserAccount, {
    onCompleted: (params) => {
      member?.refetch?.();
      form.toggle(false);
    },
    succeedMessage: "保存しました。",
    errorMessage: "エラーが発生したため、保存できませんでした。",
  });

  const handleUpdate = (value) => {
    updateMutation({
      input: {
        ...value,
        planSubscriberId: undefined,
        email: undefined
      },
    });
  };

  return render({
    ...props,
    value: member?.data?.getUserAccount,
    form: form,
    loading: member.loading || updateMutationState.loading,
    onUpdate: handleUpdate,
    onRefetch: () => member?.refetch?.(),
    ownership: ownership,
  });
};
