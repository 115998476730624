import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { searchClientsByOffset } from 'api/graphql/queries';
import { API, graphqlOperation } from "utils/graphqlOperation";
import { selector as userWidgetsSelector } from "ducks/Widgets";
import { companySelector } from "ducks/Company";
import { sampleData } from "./sample";
import { debugLog } from "utils/log";

/**
 * Containerコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうか
 * @param {Function} props.render - 子コンポーネントをレンダリングするための関数
 * @param {string} props.widgetId - 表示するウィジェットのID
 */
export const Container = ({ render, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const company = useSelector(companySelector);
  const {
    widgets: { items: widgetsArray },
  } = useSelector(userWidgetsSelector);

  const widget = widgetsArray[props.widgetId];
  const navigate = useNavigate();

  const load = useCallback(() => {
    setLoading(true);
    API.graphql(
      graphqlOperation(searchClientsByOffset, {
        offset: 0,
        limit: 5,
        sort: {
          direction: "desc",
          field: "createdAt"
        },
        supplierCompanyId: company.id,
      })
    ).then((response) => {
      const clients = response.data.searchClientsByOffset.items;
      setData(clients);
    }).catch((err) => {
      debugLog("searchClientsByOffset: ", err);
    }).finally(() => {
      setLoading(false);
    });
  }, [company.id]);

  useEffect(() => {
    if (props.editMode) {
      setData(sampleData);
    } else {
      load();
    }
  }, [props.editMode, load]);

  const handleMoreData = () => {
    navigate("/master/partner");
  };

  const onCellClick = (id) => {
    if (props.editMode) return;
    navigate(`/master/partner/${id}?tab=2`);
  }

  return render({
    ...props,
    data,
    loading,
    handleMoreData,
    name: widget?.name,
    onCellClick: onCellClick,
  });
};
