import reducer, {
  addWidgetToUserPreference,
  removeWidget,
  removeWidgetFromUserPreference,
  selector,
  resetAllWidgets,
  makeAllWidgetResizable,
  makeAllWidgetUnresizable,
  discardLayoutChange,
  updateConfigurations,
} from "./slice";

import {
  getDashboardWidgetsData,
  getUserDashboardWidgetsData,
  updateUserDashboardWidgetsData,
  updateUserDashboardWidgetsDataInBackground
} from "./api";

export default reducer;
export {
  addWidgetToUserPreference,
  removeWidget,
  removeWidgetFromUserPreference,
  selector,
  resetAllWidgets,
  makeAllWidgetResizable,
  makeAllWidgetUnresizable,
  getDashboardWidgetsData,
  getUserDashboardWidgetsData,
  updateUserDashboardWidgetsData,
  discardLayoutChange,
  updateConfigurations,
  updateUserDashboardWidgetsDataInBackground
};
