import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  searchContractsByOffset,
  getContract,
  searchClientsByOffset,
} from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { selector as userWidgetsSelector } from "ducks/Widgets";
import { companySelector } from "ducks/Company";
import { sampleData } from "./sample";
import { debugLog } from "utils/log";

/**
 * Containerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうか
 * @param {Function} props.render - 子コンポーネントをレンダリングするための関数
 * @param {string} props.widgetId - 表示するウィジェットのID
 */
export const Container = ({ render, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [navigating, setNavigating] = useState(false);
  const {
    widgets: { items: widgetsArray },
  } = useSelector(userWidgetsSelector);

  const widget = widgetsArray[props.widgetId];
  const navigate = useNavigate();
  const company = useSelector(companySelector);

  const load = useCallback(() => {
    setLoading(true);
    API.graphql(
      graphqlOperation(searchContractsByOffset, {
        offset: 0,
        limit: 5,
        sort: {
          field: "createdAt",
          direction: "desc",
        },
      })
    )
      .then((response) => {
        const contracts = response.data.searchContractsByOffset.items;
        setData(contracts);
      })
      .catch((err) => {
        debugLog("searchContractsByOffset: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (props.editMode) {
      setData(sampleData);
    } else {
      load();
    }
  }, [props.editMode, load]);

  const handleMoreData = () => {
    navigate("/master/contracts/list");
  };

  const transiteContractPage = (contractId) => {
    if (!contractId) {
      return;
    }
    setNavigating(true);
    API.graphql(
      graphqlOperation(getContract, {
        id: contractId,
      })
    ).then((contract) => {
      if (contract.data.getContract.type.name === "二者契約") {
        const targetCompany = contract.data.getContract.partyCompanies.find(
          (c) => c.id !== contract.data.getContract.ownerCompanyId
        );

        API.graphql(
          graphqlOperation(searchClientsByOffset, {
            supplierCompanyId: company.id,
          })
        )
          .then((clients) => {
            navigate({
              pathname: `/master/partner/${
                clients.data.searchClientsByOffset.items.find(
                  (item) => item.companyId === targetCompany.id
                ).id
              }/contract/${contract.data.getContract.id}`,
              search: "?alert",
            });
          })
          .catch((error) => {
            debugLog("transiteContractPage: ", error);
          })
          .finally(() => {
            setNavigating(false);
          });
      }
    });
  };

  const onClickCell = (id) => {
    if (props.editMode) return;
    transiteContractPage(id);
  };

  return render({
    ...props,
    data,
    loading,
    handleMoreData,
    name: widget?.name,
    onClickCell: onClickCell,
    navigating: navigating,
  });
};
