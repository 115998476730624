import { lightBlue, lightGreen, orange } from "@mui/material/colors";

//都道府県
export const Prefectures = [
  { code: "01", name: "北海道" },
  { code: "02", name: "青森県" },
  { code: "03", name: "岩手県" },
  { code: "04", name: "宮城県" },
  { code: "05", name: "秋田県" },
  { code: "06", name: "山形県" },
  { code: "07", name: "福島県" },
  { code: "08", name: "茨城県" },
  { code: "09", name: "栃木県" },
  { code: "10", name: "群馬県" },
  { code: "11", name: "埼玉県" },
  { code: "12", name: "千葉県" },
  { code: "13", name: "東京都" },
  { code: "14", name: "神奈川県" },
  { code: "15", name: "新潟県" },
  { code: "16", name: "富山県" },
  { code: "17", name: "石川県" },
  { code: "18", name: "福井県" },
  { code: "19", name: "山梨県" },
  { code: "20", name: "長野県" },
  { code: "21", name: "岐阜県" },
  { code: "22", name: "静岡県" },
  { code: "23", name: "愛知県" },
  { code: "24", name: "三重県" },
  { code: "25", name: "滋賀県" },
  { code: "26", name: "京都府" },
  { code: "27", name: "大阪府" },
  { code: "28", name: "兵庫県" },
  { code: "29", name: "奈良県" },
  { code: "30", name: "和歌山県" },
  { code: "31", name: "鳥取県" },
  { code: "32", name: "島根県" },
  { code: "33", name: "岡山県" },
  { code: "34", name: "広島県" },
  { code: "35", name: "山口県" },
  { code: "36", name: "徳島県" },
  { code: "37", name: "香川県" },
  { code: "38", name: "愛媛県" },
  { code: "39", name: "高知県" },
  { code: "40", name: "福岡県" },
  { code: "41", name: "佐賀県" },
  { code: "42", name: "長崎県" },
  { code: "43", name: "熊本県" },
  { code: "44", name: "大分県" },
  { code: "45", name: "宮崎県" },
  { code: "46", name: "鹿児島県" },
  { code: "47", name: "沖縄県" },
];

//　事業場の種類
export const BusinessType = [
  {
    code: "A01",
    name: "農業",
  },
  {
    code: "A02",
    name: "林業",
  },
  {
    code: "B03",
    name: "漁業（水産養殖業を除く）",
  },
  {
    code: "R88",
    name: "廃棄物処理業",
  },
];

/**
 * 一般廃棄物許可証種類を表します。
 */
export const GeneralWasteLicenseCategories = [
  {
    code: "notIncludeTransshipmentForGeneral",
    name: "一般廃棄物収集運搬業（積替を含まないもの）",
  },
  {
    code: "includeTransshipmentForGeneral",
    name: "一般廃棄物収集運搬業（積替を含むもの）",
  },
  {
    code: "disposalForGeneral",
    name: "一般廃棄物処分業",
  },
];

/**
 * 産業廃棄物許可証種類を表します。
 */
export const IndustrialWasteLicenseCategories = [
  {
    id: "0",
    code: "notIncludeTransshipmentForIndustrial",
    name: "産業廃棄物収集運搬業（積替を含まないもの）",
  },
  {
    id: "1",
    code: "includeTransshipmentForIndustrial　",
    name: "産業廃棄物収集運搬業（積替を含むもの）",
  },
  {
    id: "2",
    code: "middleDisposalForIndustrial",
    name: "産業廃棄物処分業（中間処分のみ）",
  },
  {
    id: "3",
    code: "lastDisposalForIndustrial",
    name: "産業廃棄物処分業（最終処分のみ）",
  },
  {
    id: "4",
    code: "middleAndLastDisposalForIndustrial",
    name: "産業廃棄物処分業（中間処分・最終処分）",
  },
  {
    id: "5",
    code: "notIncludeTransshipmentForSpeciallyControlledIndustrial",
    name: "特別管理産業廃棄物収集運搬業（積替を含まないもの）",
  },
  {
    id: "6",
    code: "includeTransshipmentForSpeciallyControlledIndustrial",
    name: "特別管理産業廃棄物収集運搬業（積替を含むもの）",
  },
  {
    id: "7",
    code: "middleDisposalForSpeciallyControlledIndustrial",
    name: "特別管理産業廃棄物処分業（中間処分のみ）",
  },
  {
    id: "8",
    code: "lastDisposalForSpeciallyControlledIndustrial",
    name: "特別管理産業廃棄物処分業（最終処分のみ）",
  },
  {
    id: "9",
    code: "middleAndLastDisposalForSpeciallyControlledIndustrial",
    name: "特別管理産業廃棄物処分業（中間処分・最終処分）",
  },
];

/**
 * 廃棄物許可証種類を表します。
 */
export const WasteLicenseCategories = [
  ...GeneralWasteLicenseCategories,
  ...IndustrialWasteLicenseCategories,
];

export const toWasteLicenseCategory = (codeOrId) => {
  if (codeOrId) {
    return (
      GeneralWasteLicenseCategories.find((item) => item.code === codeOrId) ||
      IndustrialWasteLicenseCategories.find(
        (item) => item.code === codeOrId || item.id === codeOrId
      )
    );
  }

  return null;
};

/**
 * 都道府県・政令市を表します。
 */
export const PrefecturesAndDesignatedCities = [
  {
    code: "001",
    name: "北海道",
  },
  {
    code: "002",
    name: "青森県",
  },
  {
    code: "003",
    name: "岩手県",
  },
  {
    code: "004",
    name: "宮城県",
  },
  {
    code: "005",
    name: "秋田県",
  },
  {
    code: "006",
    name: "山形県",
  },
  {
    code: "007",
    name: "福島県",
  },
  {
    code: "008",
    name: "茨城県",
  },
  {
    code: "009",
    name: "栃木県",
  },
  {
    code: "010",
    name: "群馬県",
  },
  {
    code: "011",
    name: "埼玉県",
  },
  {
    code: "012",
    name: "千葉県",
  },
  {
    code: "013",
    name: "東京都",
  },
  {
    code: "014",
    name: "神奈川県",
  },
  {
    code: "015",
    name: "新潟県",
  },
  {
    code: "016",
    name: "富山県",
  },
  {
    code: "017",
    name: "石川県",
  },
  {
    code: "018",
    name: "福井県",
  },
  {
    code: "019",
    name: "山梨県",
  },
  {
    code: "020",
    name: "長野県",
  },
  {
    code: "021",
    name: "岐阜県",
  },
  {
    code: "022",
    name: "静岡県",
  },
  {
    code: "023",
    name: "愛知県",
  },
  {
    code: "024",
    name: "三重県",
  },
  {
    code: "025",
    name: "滋賀県",
  },
  {
    code: "026",
    name: "京都府",
  },
  {
    code: "027",
    name: "大阪府",
  },
  {
    code: "028",
    name: "兵庫県",
  },
  {
    code: "029",
    name: "奈良県",
  },
  {
    code: "030",
    name: "和歌山県",
  },
  {
    code: "031",
    name: "鳥取県",
  },
  {
    code: "032",
    name: "島根県",
  },
  {
    code: "033",
    name: "岡山県",
  },
  {
    code: "034",
    name: "広島県",
  },
  {
    code: "035",
    name: "山口県",
  },
  {
    code: "036",
    name: "徳島県",
  },
  {
    code: "037",
    name: "香川県",
  },
  {
    code: "038",
    name: "愛媛県",
  },
  {
    code: "039",
    name: "高知県",
  },
  {
    code: "040",
    name: "福岡県",
  },
  {
    code: "041",
    name: "佐賀県",
  },
  {
    code: "042",
    name: "長崎県",
  },
  {
    code: "043",
    name: "熊本県",
  },
  {
    code: "044",
    name: "大分県",
  },
  {
    code: "045",
    name: "宮崎県",
  },
  {
    code: "046",
    name: "鹿児島県",
  },
  {
    code: "047",
    name: "沖縄県",
  },
  {
    code: "050",
    name: "旭川市",
  },
  {
    code: "051",
    name: "札幌市",
  },
  {
    code: "052",
    name: "函館市",
  },
  {
    code: "054",
    name: "仙台市",
  },
  {
    code: "055",
    name: "千葉市",
  },
  {
    code: "056",
    name: "横浜市",
  },
  {
    code: "057",
    name: "川崎市",
  },
  {
    code: "058",
    name: "横須賀市",
  },
  {
    code: "059",
    name: "新潟市",
  },
  {
    code: "060",
    name: "金沢市",
  },
  {
    code: "061",
    name: "岐阜市",
  },
  {
    code: "062",
    name: "静岡市",
  },
  {
    code: "063",
    name: "浜松市",
  },
  {
    code: "064",
    name: "名古屋市",
  },
  {
    code: "065",
    name: "京都市",
  },
  {
    code: "066",
    name: "大阪市",
  },
  {
    code: "067",
    name: "堺市",
  },
  {
    code: "068",
    name: "東大阪市",
  },
  {
    code: "069",
    name: "神戸市",
  },
  {
    code: "070",
    name: "姫路市",
  },
  {
    code: "071",
    name: "尼崎市",
  },
  {
    code: "072",
    name: "和歌山市",
  },
  {
    code: "073",
    name: "広島市",
  },
  {
    code: "074",
    name: "呉市",
  },
  {
    code: "075",
    name: "下関市",
  },
  {
    code: "076",
    name: "北九州市",
  },
  {
    code: "077",
    name: "福岡市",
  },
  {
    code: "078",
    name: "大牟田市",
  },
  {
    code: "079",
    name: "長崎市",
  },
  {
    code: "080",
    name: "佐世保市",
  },
  {
    code: "081",
    name: "熊本市",
  },
  {
    code: "082",
    name: "鹿児島市",
  },
  {
    code: "083",
    name: "岡山市",
  },
  {
    code: "084",
    name: "宇都宮市",
  },
  {
    code: "085",
    name: "富山市",
  },
  {
    code: "086",
    name: "秋田市",
  },
  {
    code: "087",
    name: "郡山市",
  },
  {
    code: "088",
    name: "大分市",
  },
  {
    code: "089",
    name: "松山市",
  },
  {
    code: "090",
    name: "豊田市",
  },
  {
    code: "091",
    name: "福山市",
  },
  {
    code: "092",
    name: "高知市",
  },
  {
    code: "093",
    name: "宮崎市",
  },
  {
    code: "094",
    name: "いわき市",
  },
  {
    code: "095",
    name: "長野市",
  },
  {
    code: "096",
    name: "豊橋市",
  },
  {
    code: "097",
    name: "高松市",
  },
  {
    code: "098",
    name: "相模原市",
  },
  {
    code: "099",
    name: "西宮市",
  },
  {
    code: "100",
    name: "倉敷市",
  },
  {
    code: "101",
    name: "さいたま市",
  },
  {
    code: "102",
    name: "奈良市",
  },
  {
    code: "103",
    name: "川越市",
  },
  {
    code: "104",
    name: "船橋市",
  },
  {
    code: "105",
    name: "岡崎市",
  },
  {
    code: "106",
    name: "高槻市",
  },
  {
    code: "108",
    name: "青森市",
  },
  {
    code: "109",
    name: "八王子市",
  },
  {
    code: "110",
    name: "盛岡市",
  },
  {
    code: "111",
    name: "柏市",
  },
  {
    code: "112",
    name: "久留米市",
  },
  {
    code: "114",
    name: "前橋市",
  },
  {
    code: "115",
    name: "大津市",
  },
  {
    code: "116",
    name: "高松市",
  },
  {
    code: "116",
    name: "高崎市",
  },
  {
    code: "118",
    name: "豊中市",
  },
  {
    code: "119",
    name: "那覇市",
  },
  {
    code: "120",
    name: "枚方市",
  },
  {
    code: "121",
    name: "越谷市",
  },
  {
    code: "122",
    name: "八戸市",
  },
  {
    code: "124",
    name: "福島市",
  },
  {
    code: "125",
    name: "川口市",
  },
  {
    code: "126",
    name: "八尾市",
  },
  {
    code: "127",
    name: "明石市",
  },
  {
    code: "128",
    name: "鳥取市",
  },
  {
    code: "129",
    name: "松江市",
  },
];

/**
 * 番号から廃棄物許可証情報を取得します。
 * @param {string} number 番号(10または11桁)
 * @returns {object}
 */
export const toWasteLicense = (number) => {
  if (!number || String(number).length === 0) {
    // 番号が指定されてなければnullを返す
    return null;
  }

  const numberStr = String(number);
  const issuerCode = numberStr.length >= 3 ? numberStr.substring(0, 3) : null;
  const categoryCode = numberStr.length >= 4 ? numberStr.substring(3, 4) : null;
  const issuerFreeCode =
    numberStr.length >= 5 ? numberStr.substring(4, 5) : null;
  const id = numberStr.length === 11 ? numberStr.substring(5) : null;
  const formatNumber =
    numberStr.length === 11
      ? `第${issuerCode}-${categoryCode}-${issuerFreeCode}${id}号`
      : null;

  return {
    // 元の番号
    sourceNumber: number,
    // 整形された番号
    number: formatNumber,
    // 許可主体番号(都道府県・政令市)
    issuerCode: issuerCode,
    // 業の区分番号
    categoryCode: categoryCode,
    // 許可主体が自由に決める値
    issuerFreeCode: issuerFreeCode,
    // 固有番号
    id: id,
    // 許可主体
    issuer: PrefecturesAndDesignatedCities.find(
      (item) => item.code === issuerCode
    ),
    // 区分
    category: IndustrialWasteLicenseCategories.find(
      (item) => item.id === categoryCode
    ),
  };
};

/**
 * 廃棄物処理契約の種類を表します。
 */
export const WasteOutsourcingContractStyle = [
  {
    number: 2,
    code: "secondParty",
    name: "二者契約",
  },
  {
    number: 3,
    code: "thirdParty",
    name: "三者契約",
  },
  {
    number: 4,
    code: "fourthParty",
    name: "四者契約",
  },
  {
    number: 5,
    code: "fifthParty",
    name: "五者契約",
  },
  {
    number: 6,
    code: "sixthParty",
    name: "六者契約",
  },
  {
    number: 7,
    code: "seventhParty",
    name: "七者契約",
  },
  {
    number: 8,
    code: "eighthParty",
    name: "八者契約",
  },
  {
    number: 9,
    code: "ninthParty",
    name: "九者契約",
  },
  {
    number: 10,
    code: "tenthParty",
    name: "十者契約",
  },
];

/**
 * 十干を表します。
 */
export const HeavenlyStems = [
  {
    code: 1,
    name: "甲",
  },
  {
    code: 2,
    name: "乙",
  },
  {
    code: 3,
    name: "丙",
  },
  {
    code: 4,
    name: "丁",
  },
  {
    code: 5,
    name: "戊",
  },
  {
    code: 6,
    name: "己",
  },
  {
    code: 7,
    name: "庚",
  },
  {
    code: 8,
    name: "辛",
  },
  {
    code: 9,
    name: "壬",
  },
  {
    code: 10,
    name: "癸",
  },
];

/**
 * 運転免許を表します。
 */
export const DriversLicenses = [
  {
    code: 1,
    name: "普通自動車免許",
  },
  {
    code: 2,
    name: "準中型自動車免許",
  },
  {
    code: 3,
    name: "中型自動車免許",
  },
  {
    code: 4,
    name: "大型自動車免許",
  },
  {
    code: 5,
    name: "大型特殊自動車免許",
  },
  {
    code: 6,
    name: "牽引免許",
  },
  {
    code: 7,
    name: "原動機付自転車免許",
  },
  {
    code: 8,
    name: "小型特殊自動車免許",
  },
  {
    code: 9,
    name: "普通自動二輪車免許",
  },
  {
    code: 10,
    name: "大型自動二輪車免許",
  },
];

/**
 * 月を表します。
 */
export const Month = [
  {
    code: "January",
    name: "1月",
  },
  {
    code: "February",
    name: "2月",
  },
  {
    code: "March",
    name: "3月",
  },
  {
    code: "April",
    name: "4月",
  },
  {
    code: "May",
    name: "5月",
  },
  {
    code: "June",
    name: "6月",
  },
  {
    code: "July",
    name: "7月",
  },
  {
    code: "August",
    name: "8月",
  },
  {
    code: "September",
    name: "9月",
  },
  {
    code: "October",
    name: "10月",
  },
  {
    code: "November",
    name: "11月",
  },
  {
    code: "December",
    name: "12月",
  },
];

/**
 * 週を表します。
 */
export const Week = [
  {
    code: "FirstWeek",
    name: "1週",
  },
  {
    code: "SecondWeek",
    name: "2週",
  },
  {
    code: "ThirdWeek",
    name: "3週",
  },
  {
    code: "FourthWeek",
    name: "4週",
  },
  {
    code: "FifthWeek",
    name: "5週",
  },
];

/**
 * 曜日を表します。
 */
export const DayOfWeek = [
  {
    code: "Sunday",
    name: "日曜日",
    shortName: "日",
  },
  {
    code: "Monday",
    name: "月曜日",
    shortName: "月",
  },
  {
    code: "Tuesday",
    name: "火曜日",
    shortName: "火",
  },
  {
    code: "Wednesday",
    name: "水曜日",
    shortName: "水",
  },
  {
    code: "Thursday",
    name: "木曜日",
    shortName: "木",
  },
  {
    code: "Friday",
    name: "金曜日",
    shortName: "金",
  },
  {
    code: "Saturday",
    name: "土曜日",
    shortName: "土",
  },
];

/**
 * 事業場の状態を表します。
 */
export const EditableWorkplaceStatus = [
  {
    code: "active",
    name: "開店",
  },
  {
    code: "closedown",
    name: "閉店",
  },
];

/**
 * 事業場の区分と対応したカラーを表します。
 */
export const WorkplaceCategoryColorMap = {
  dischargeSite: lightBlue[500],
  transshipmentStorageFacility: lightGreen[500],
  disposalPlant: orange[500],
};

export const modeList = [
  { id: "daily", name: "日別集計" },
  { id: "weekly", name: "週別集計" },
  { id: "monthly", name: "月別集計" },
  { id: "yearly", name: "年別集計" },
  { id: "totaling", name: "合算" },
];
