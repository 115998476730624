import { useLocation, useNavigate } from "react-router";
import { fromQueryString, toQueryString } from "utils/useXGridComponents";

/**
 * 契約の表示を行うコンテナコンポーネントです。
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleTransite = (e) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(e.info.search),
      },
      {
        replace: true,
      }
    );
    navigate(
      `${location.pathname}/${
        e?.value?.type === "industrial" ? "industrial" : "general"
      }/${e?.value?.id}`
    );
  };

  return render({
    onTransite: handleTransite,
    initialState: {
      sort: {
        direction: "desc",
        field: "lastUpdatedAt",
      },
      pageSize: 10,
      ...fromQueryString(location.search),
    },
    ...props,
  });
};
