import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { DateTime } from "luxon";

export class PhoneNumberFormatUtil {
  static format(value, formatType) {
    if (!value) {
      return value;
    }
    const region = "JP";
    const util = PhoneNumberUtil.getInstance();
    const number = util.parseAndKeepRawInput(value, region);
    if (!util.isValidNumberForRegion(number, region)) {
      return value;
    }

    return util.format(number, formatType);
  }

  static formatInternational(value) {
    return this.format(value, PhoneNumberFormat.INTERNATIONAL);
  }

  static formatNational(value) {
    return this.format(value, PhoneNumberFormat.NATIONAL);
  }

  static isValid(value) {
    const util = PhoneNumberUtil.getInstance();
    const region = "JP";
    const number = util.parseAndKeepRawInput(value, region);
    return util.isValidNumberForRegion(number, region);
  }
}

/**
 * オブジェクトから住所文字列を生成します。
 * @param {object} value オブジェクト
 * @returns {string}
 */
export const formatDisplayAddress = (value) => {
  if (!value) {
    return "";
  }

  const { postalCode, prefecture, city, streetAddress, otherAddress } = value;

  return `${postalCode ? `〒${postalCode}` : ""}${prefecture?.name ?? ""}${city ?? ""
    }${streetAddress ?? ""}${otherAddress ?? ""}`;
};

/**
 * ISO形式の日付文字列から指定のフォーマット日付文字列を取得します。
 * @param {object} props プロパティ
 * @param {string} props.source ISO日付文字列
 * @param {string} props.setZone ゾーン
 * @param {string} props.destFormat 出力文字列フォーマット
 * @returns {string | null} 文字列
 */
export const formatDisplayDateFromISO = ({
  source = null,
  setZone = "Asia/Tokyo",
  destFormat = "yyyy/MM/dd",
}) => {
  if (!!source) {
    return DateTime.fromISO(source, { setZone: setZone }).toFormat(destFormat);
  } else {
    return null;
  }
};

/**
 * 対象の日付文字列を指定のフォーマットで取得します。
 * @param {object} props プロパティ
 * @param {string} props.source 元日付文字列
 * @param {string} sprops.sourceFormat 変換前フォーマット
 * @param {string} props.destFormat 変換フォーマット
 * @returns {string}
 */
export const formatDisplayDate = ({
  source = null,
  sourceFormat = "yyyy-MM-dd",
  destFormat = "yyyy/MM/dd",
}) => {
  if (!source || source === "") {
    return "";
  }

  return DateTime.fromFormat(source, sourceFormat)
    .setLocale("jp-JP")
    .toFormat(destFormat);
};

/**
 * 空文字列の場合 null を返します。
 * @param {string} value 文字列
 * @returns {null | object}
 */
export const emptyStringWhenNull = (value) => {
  if (value === "") {
    return null;
  }

  return value;
};

/**
 * 数字がintであるか確認します。
 * @param {any} n 数字
 * @returns {boolean}
 */
export const isInt = (n) => {
  return Number(n) === n && n % 1 === 0;
};

/**
 * 数字がfloatであるか確認します。
 * @param {any} n 数字
 * @returns {boolean}
 */
export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};

/**
 * 文字列を数字に変換します。
 * @param {string} value 文字列
 * @returns {null | number}
 */
export const parseNumber = (value) => {
  if (!value || value === "") {
    return null;
  }

  const result = parseFloat(value);

  return result === isNaN ? null : result;
};

/**
 * JSのDateからISO 日付の文字列に変換します。
 * @param {Date} value JSのDate
 * @returns {null | string} ISO日付文字列 または null
 */
export const formatJSDateToISODate = (value) => {
  if (!value) {
    return null;
  }

  if (typeof value === "string" || value instanceof String) {
    if (DateTime.fromISO(value).isValid) {
      // 値が文字列であった場合、iso形式であるか確認してiso形式ならそのまま流す
      // それ以外は不正とみなす
      return value;
    } else {
      throw new TypeError("値はobject型ではありませんでした。");
    }
  }

  if (typeof value !== "object") {
    throw new TypeError("値はobject型ではありませんでした。");
  }

  if (value instanceof Date) {
    if (Number.isNaN(value.getTime())) {
      throw new Error("無効な日付が引き渡されました。");
    }

    return DateTime.fromJSDate(value).toFormat("yyyy-MM-dd");
  }

  throw new TypeError("値はDate型ではありませんでした。");
};

/**
 * ISO日付の文字列からJSのDateに変換します。
 * @param {Date} value ISO日付の文字列
 * @returns {null | Date} Dateオブジェクト または null
 */
export const formatISODateToJSDate = (value) => {
  if (!value) {
    return null;
  }

  if (typeof value === "string" || value instanceof String) {
    return DateTime.fromISO(value).toJSDate();
  }

  throw new TypeError("値はString型ではありませんでした。");
};


/**
 * UTC日付をJST日付に変換します。
 * @param {string} utcDate UTC日付文字列
 * @param {string} [zone="utc"] 入力ゾーン
 * @param {string} [format="yyyy-MM-dd HH:mm:ss"] 出力フォーマット
 * @returns {string} JST日付文字列
 */
export const convertUtcToJst = ({
  utcDate,
  zone = "utc",
  format = "yyyy-MM-dd HH:mm:ss"
}) => {
  if (!utcDate) {
    return "";
  }
  return DateTime.fromISO(utcDate, { zone }).setZone('Asia/Tokyo').toFormat(format);
}
