import { format } from "date-fns";
export const sampleData = [
  {
    id: 1,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "山田商事株式会社",
    type: {
      name: "二者契約",
    },
  },
  {
    id: 2,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "株式会社グリーンソリューション",
    type: {
      name: "リース契約",
    },
  },
  {
    id: 3,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "東京エコシステム株式会社",
    type: {
      name: "委託契約",
    },
  },
  {
    id: 4,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "エコフューチャー合同会社",
    type: {
      name: "業務委託契約",
    },
  },
  {
    id: 5,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "株式会社クリーンエネルギー",
    type: {
      name: "業務委託契約",
    },
  },
];
