import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {function} props.onClick - ボタンクリック時のコールバック関数。
 * @returns {JSX.Element} カスタマイズアイコンコンポーネント。
 */
export const CustomizeSettingIcon = containerPresententer(Container, Presententer);
