import { Alert } from "@mui/material";
import { keyframes } from "@mui/system";

const blink = (theme, severity) => keyframes`
  0% {
    border-color: transparent;
  }
  50% {
    border-color: ${theme.palette[severity].main}
  }
  100% {
    border-color: transparent;
  }
`;

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {boolean} [props.animate=false] - アラートボーダーをアニメーションするかどうか。
 * @param {import("@mui/material").AlertProps["variant"]} props.variant - アラートのバリアント。
 * @param {import("@mui/material").AlertProps["severity"]} [props.severity="warning"] - アラートの重大度。
 * @param {import("@mui/material").Theme} props.theme - The theme object.
 * @param {React.ReactNode} props.children - アラートの内容。"severity"]} [props.severity="warning"] - The severity of the alert.
 * @returns {JSX.Element} アニメーションされたアラートコンポーネント。
 */

export const Presententer = ({
  theme,
  animate = false,
  variant,
  severity = "warning",
  children,
}) => {
  return (
    <Alert
      sx={{
        fontWeight: 800,
        animation: animate ? `${blink(theme, severity)} 3s infinite` : "none",
        borderWidth: 2,
        flex: 1,
        borderColor: animate ? "transparent" : theme.palette[severity]?.main,
      }}
      variant={variant}
      severity={severity}
    >
      {children}
    </Alert>
  );
};
