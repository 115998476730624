import { useState, useEffect, useCallback } from "react";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useSelector } from "react-redux";
import { getAlert } from "api/graphql/queries";
import { selector as userWidgetsSelector } from "ducks/Widgets";
import { useOpener } from "utils/useOpener";
import { debugLog } from "utils/log";


/**
 * Containerコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.widgetId - 表示するウィジェットのID
 * @param {boolean} props.editMode - コンポーネントが編集モードかどうかを示すフラグ
 */
export const Container = ({ render, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const dialog = useOpener();
  const {
    widgets: { items: widgetsArray },
  } = useSelector(userWidgetsSelector);

  const widget = widgetsArray[props.widgetId];

  const load = useCallback(() => {
    setLoading(true);
    return API.graphql(
      graphqlOperation(getAlert, {
        alertTypes: ["missingCoordinates"],
      })
    )
      .then((res) => {
        setValue(res.data.getAlert?.missingCoordinateCount);
      })
      .catch((err) => {
        debugLog("getAlertの呼び出し失敗: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (props?.editMode) {
      setValue(100);
    } else {
      load();
    }
  }, [props?.editMode, load]);

  const handleClick = () => {
    dialog.toggle(true);
  };

  return render({
    loading,
    value,
    name: widget?.name,
    handleClick,
    dialog,
    ...props,
  });
};
