import {
  format,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  isBefore,
  isSameDay,
  getWeek,
} from "date-fns";
import { modeList } from "utils/Context";

// Function to generate data based on the selected mode
const generateData = (startDate, endDate, mode) => {
  const data = [];
  let currentDate = new Date(startDate);

  switch (mode) {
    case modeList[0].id:
      while (
        isBefore(currentDate, new Date(endDate)) ||
        isSameDay(currentDate, new Date(endDate))
      ) {
        data.push({
          date: format(currentDate, "yyyy-MM-dd"),
          kg: Math.floor(Math.random() * 500),
          t: Math.random().toFixed(2),
          m3: Math.floor(Math.random() * 100),
          リットル: Math.floor(Math.random() * 1000),
          "個・台": Math.floor(Math.random() * 100),
        });
        currentDate = addDays(currentDate, 1);
      }
      break;

    case modeList[1].id:
      while (
        isBefore(currentDate, new Date(endDate)) ||
        isSameDay(currentDate, new Date(endDate))
      ) {
        data.push({
          date: `${format(currentDate, "yyyy")}.${getWeek(currentDate)}`,
          kg: Math.floor(Math.random() * 500),
          t: Math.random().toFixed(2),
          m3: Math.floor(Math.random() * 100),
          リットル: Math.floor(Math.random() * 1000),
          "個・台": Math.floor(Math.random() * 100),
        });
        currentDate = addWeeks(currentDate, 1);
      }
      break;

    case modeList[2].id:
      while (
        isBefore(currentDate, new Date(endDate)) ||
        isSameDay(currentDate, new Date(endDate))
      ) {
        data.push({
          date: format(currentDate, "yyyy.MM"),
          kg: Math.floor(Math.random() * 500),
          t: Math.random().toFixed(2),
          m3: Math.floor(Math.random() * 100),
          リットル: Math.floor(Math.random() * 1000),
          "個・台": Math.floor(Math.random() * 100),
        });
        currentDate = addMonths(currentDate, 1);
      }
      break;

    case modeList[3].id:
      while (
        isBefore(currentDate, new Date(endDate)) ||
        isSameDay(currentDate, new Date(endDate))
      ) {
        data.push({
          date: format(currentDate, "yyyy"),
          kg: Math.floor(Math.random() * 500),
          t: Math.random().toFixed(2),
          m3: Math.floor(Math.random() * 100),
          リットル: Math.floor(Math.random() * 1000),
          "個・台": Math.floor(Math.random() * 100),
        });
        currentDate = addYears(currentDate, 1);
      }
      break;

    case modeList[4].id:
      data.push({
        date: null,
        kg: Math.floor(Math.random() * 500),
        t: Math.random().toFixed(2),
        m3: Math.floor(Math.random() * 100),
        リットル: Math.floor(Math.random() * 1000),
        "個・台": Math.floor(Math.random() * 100),
      });
      break;

    default:
      return [];
  }

  return data;
};

// Function to get sample data based on mode and date range
export const getSampleDate = (start, end, mode) => {
  return {
    header: {
      kg: true,
      t: true,
      m3: true,
      リットル: true,
      "個・台": true,
    },
    items: generateData(start, end, mode),
  };
};
