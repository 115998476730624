import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import jaLocale from "date-fns/locale/ja";
import { debugLog } from "utils/log";
import { TextField } from "views/atoms";

/**
 * 日付の選択の表示を行うプレゼンテーションコンポーネントです
 */
export const Presententer = ({
  placeholder,
  value,
  onChange,
  helperText,
  error,
  label,
  inputVariant = "outlined",
  required,
  size,
  width = 180,
  ...props
}) => {
  return (
    <LocalizationProvider
      utils={DateFnsUtils}
      adapterLocale={jaLocale}
      dateAdapter={AdapterDateFns}
      dateFormats={{ monthAndYear: "yyyy年MM月" }}
    >
      <DatePicker
        onError={(err) => debugLog(`DatePicker - Error: ${err}`)}
        onAccept={(res) => debugLog(`DatePicker - Accept: ${res}`)}
        value={value}
        InputAdornmentProps={{ position: "end" }}
        onChange={onChange}
        PopoverProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        mask={"____/__/__"}
        renderInput={(parmas) => {
          return (
            <TextField
              {...parmas}
              label={label}
              required={required}
              variant={inputVariant}
              helperText={helperText}
              error={error}
              size={size}
              sx={{
                width: width,
              }}
            />
          );
        }}
        toolbarTitle="日付選択"
        okText="決定"
        cancelText={"キャンセル"}
        clearText="クリア"
        toolbarFormat="yyyy年MM月dd日"
        inputRef={props?.ref}
        {...props}
      />
    </LocalizationProvider>
  );
};
