import React from "react";
import { Box, IconButton, Divider, Typography } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {React.ReactNode} props.children - 子要素。
 * @param {() => void} props.handleClose - 閉じるボタンクリック時のコールバック。
 * @param {boolean} props.editMode - 編集モードかどうか。
 * @param {string} props.widgetName - ウィジェット名。
 * @returns {JSX.Element} ドラッグ可能なカードコンポーネント。
 */
export const Presententer = ({
  children,
  handleClose,
  editMode,
  widgetName,
}) => {
  return (
    <>
      {editMode && (
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            zIndex: 1,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <IconButton
              className="drag-handle"
              sx={{
                cursor: "grab",
                p: 1,
                m: "2px",
                width: 40,
                height: 35,
                borderRadius: 1,
              }}
            >
              <DragIndicatorIcon />
            </IconButton>
            <Typography
              sx={{
                p: 1,
                fontSize: "0.9rem",
                fontWeight: "bold",
              }}
            >
              {widgetName}
            </Typography>
            <IconButton
              sx={{
                ":hover": {
                  backgroundColor: "rgba(0,0,0,0.1)",
                },
              }}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          p: 1,
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </>
  );
};
