import QueryBuilder from "@mui/icons-material/QueryBuilder";
import { Box, Link, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { If } from "views/atoms";
import { ReactToolTip } from "views/molecules";

/**
 * 回収場所を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box
    boxShadow={2}
    bgcolor={props.isActive ? "#C9E3DC" : "#fff"}
    borderRadius={1}
    p={1}
    mt={2}
    mr={1}
    fontSize="14px"
  >
    {props.value?.order && (
      <Box bgcolor="text.primary" className={props.classes.box__number}>
        {props.value.order}
      </Box>
    )}
    <Box display="flex" alignItems="center">
      <Box
        fontWeight="fontWeightBold"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {`${props?.value?.wasteGeneratorCompany?.name ?? ""}`}
      </Box>
    </Box>
    <Box color="text.secondary">
      <Box
        fontWeight="fontWeightBold"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {`${props?.value?.wasteCollectionWorkplace?.name ?? ""}`}
      </Box>
    </Box>
    <Box
      color="text.secondary"
      sx={{
        fontSize: "12px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {`${
        props?.value?.waste?.type?.smallClass?.name ||
        props?.value?.waste?.type?.middleClass?.name ||
        props?.value?.waste?.type?.largeClass?.name ||
        ""
      }`}
    </Box>
    <Box
      color="text.secondary"
      sx={{
        fontSize: "12px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {`${
        props?.value?.waste?.name
          ? `${props?.value?.waste?.name}（${
              props?.value?.wasteQuantity ?? ""
            }${props.value?.waste?.quantityUnit.name ?? ""}）`
          : `${props?.value?.wasteQuantity ?? ""}${
              props.value?.waste?.quantityUnit.name ?? ""
            }`
      }`}
    </Box>
    <Box
      color="text.secondary"
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <QueryBuilder
          fontSize="small"
          sx={{ marginRight: (theme) => theme.spacing(1) }}
        />
        <Box>
          {/* UNDONE: 配車表の回収サイクルの表示 */}
          {/* <Box
          color="text.secondary"
          sx={{
            fontSize: "11px",
          }}
        >{`${props.value?.scheduled} `}</Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                fontSize: "11px",
              }}
            >
              {`${
                props?.value.scheduleDate
                  ? DateTime.fromISO(props?.value?.scheduleDate).toFormat(
                      "yyyy/MM/dd"
                    )
                  : ""
              } `}
            </span>
            <span
              style={{
                fontSize: "11px",
              }}
            >
              {`${
                props?.value?.scheduleTimeRangeStart
                  ? props?.value?.scheduleTimeRangeStart.substring(0, 5)
                  : ""
              } ～ ${
                props?.value?.scheduleTimeRangeEnd
                  ? props?.value?.scheduleTimeRangeEnd.substring(0, 5)
                  : ""
              }`}
            </span>
          </Box>
        </Box>
      </Box>
      <If condition={!props.value?.wasteCollectionWorkplace?.position}>
        <ReactToolTip
          size="small"
          variant="outlined"
          color="warning"
          title="座標情報未設定"
          sx={{
            borderRadius: 2,
            padding: "5px 3px",
            zIndex: 10,
            fontSize: 11,
          }}
          placement="right"
        >
          <>
            <Typography textAlign="center">
              事業場の編集から設定することができます。
            </Typography>
            <Typography textAlign="center">
              設定することでマップに表示されます。
            </Typography>
            <br></br>
            <Box textAlign="center">
              <Link
                component="button"
                onClick={() => {
                  props.navigateTo(
                    `/master/partner/${props.value.wasteGeneratorCompany?.id}/workplace/${props.value.wasteCollectionWorkplace?.id}?tab=2`
                  );
                }}
                sx={{
                  cursor: "pointer",
                  padding: 2,
                }}
                textAlign="center"
                underline="always"
              >
                すぐに設定する場合はこちら
              </Link>
            </Box>
            <br></br>
            <Typography textAlign="center">
              ※現在の作業は破棄されます。
            </Typography>
          </>
        </ReactToolTip>
      </If>
      <If
        condition={
          props?.value.scheduleDate
            ? DateTime.fromISO(props?.value?.scheduleDate).toFormat(
                "yyyy/MM/dd"
              ) === props.nextDay?.toFormat("yyyy/MM/dd")
            : false
        }
      >
        <Box
          sx={{
            fontSize: "0.8rem",
            color: "#ff4569",
            border: "1px solid #ff4569",
            borderRadius: "4px",
            padding: "2px",
            marginRight: "-2px",
          }}
        >
          {"翌日分"}
        </Box>
      </If>
    </Box>
  </Box>
);
