import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { searchCarryWorkJournalsByOffset } from "api/graphql/queries";
import { selector as userWidgetsSelector } from "ducks/Widgets";
import { selector as userSelector } from "ducks/User";
import { debugLog } from "utils/log";
import { samplePieData } from "./sample";
import { format } from "date-fns";

const data = [
  { name: "回収完了", value: 0, id: "completed" },
  { name: "スキップされた回収", value: 0, id: "skipped" },
  { name: "未回収", value: 0, id: "incomplete" },
];


/**
 * Containerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうか
 * @param {Function} props.render - 子コンポーネントをレンダリングするための関数
 * @param {string} props.widgetId - 表示するウィジェットのID
 */
export const Container = ({ render, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    widgets: { items: widgetsArray },
  } = useSelector(userWidgetsSelector);

  const { user } = useSelector(userSelector);

  const widget = widgetsArray[props.widgetId];

  const load = useCallback(() => {
    setLoading(true);
    return API.graphql(
      graphqlOperation(searchCarryWorkJournalsByOffset, {
        filter: { date: { eq: format(new Date(), "yyyy-MM-dd") } },
      })
    )
      .then((res) => {
        const courses = res.data.searchCarryWorkJournalsByOffset?.items?.filter(
          (item) => {
            return item.assignedUsers.some((u) => u.id === user.id);
          }
        );
        if (courses.length === 0) {
          setTasks(data);
          return;
        }
        const total = courses.reduce(
          (acc, item) => {
            acc.completed += item.taskDoneCount;
            acc.skipped += item.taskSkippedCount;
            acc.totalPointTotal += item.pointTotal;
            acc.total += item.taskTotal;
            return acc;
          },
          {
            completed: 0,
            skipped: 0,
            totalPointTotal: 0,
            total: 0,
          }
        );
        setTasks(
          data.map((task) => {
            if (task.id === "incomplete") {
              return {
                ...task,
                value: total.total - total.completed - total.skipped,
              };
            }
            return { ...task, value: total[task.id] || 0 };
          })
        );
        setTotal(total);
      })
      .catch((err) => {
        debugLog("  getLogisticsWorkAllocationCourses: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.id]);

  useEffect(() => {
    if (props?.editMode) {
      setTasks(samplePieData);
    } else {
      load();
    }
  }, [props?.editMode, load]);

  const onMouseEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setActiveIndex(0);
  };

  return render({
    tasks,
    onMouseEnter,
    activeIndex,
    onMouseLeave,
    loading,
    name: widget?.name,
    total,
    ...props,
  });
};
