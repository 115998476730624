/**
 * @param {object} props - プロパティオブジェクト。
 * @param {function} props.onClick - ボタンクリック時のコールバック関数。
 * @returns {JSX.Element} カスタマイズアイコンコンポーネント。
 */
export const Container = ({ render, ...props }) => {
  return render({
    ...props,
  });
};
