import { format } from "date-fns";

const generateThisWeekStartEndDate = () => {
  const today = new Date();
  const day = today.getDay();
  const start = new Date(today);
  const end = new Date(today);

  start.setDate(start.getDate() - day);
  end.setDate(end.getDate() + 6 - day);

  return {
    start: format(start, "yyyy-MM-dd"),
    end: format(end, "yyyy-MM-dd"),
  };
};
export const widgetIds = {
  PERMIT_EXPIRATION_ALERTS: "permitExpirationAlerts",
  AGREEMENT_EXPIRATION_ALERTS: "agreementExpirationAlerts",
  MISSED_VEHICLE_ALERTS: "missedVehicleAlerts",
  UNCOLLECTED_ALERTS: "uncollectedAlerts",
  CONTAINER_INSTALLATION_ALERTS: "containerInstallationAlerts",
  COORDINATE_NOT_REGISTER_ALERTS: "coordinateNotRegisterAlerts",
  VEHICLE_REMIND_ALERTS: "vehicleRemindAlerts",
  TODAYS_COLLECTION_PROGRESS: "todaysCollectionProgress",
  COLLECTION_VOLUME_CHART: "collectionVolumeChart",
  DRIVERS_TIMELINE: "driverTimeline",
  ALL_COURSE_STATUS: "allCourseStatus",
  RECENT_CONTRACT_START_LIST: "recentContractStartList",
  RECENT_ADDED_ACCOUNTS: "recentAddedAccounts",
  RECENT_ADDED_SPOT_COLLECTIONS: "recentAddedSpotCollections",
};

const defaultConfigurations = {
  [widgetIds.COLLECTION_VOLUME_CHART]: {
    chartType: "bar",
    dateRange: generateThisWeekStartEndDate(),
    mode: "daily",
  },
  [widgetIds.TODAYS_COLLECTION_PROGRESS]: {},
  [widgetIds.DRIVERS_TIMELINE]: {},
  [widgetIds.PERMIT_EXPIRATION_ALERTS]: {},
  [widgetIds.AGREEMENT_EXPIRATION_ALERTS]: {},
  [widgetIds.MISSED_VEHICLE_ALERTS]: {},
  [widgetIds.UNCOLLECTED_ALERTS]: {},
  [widgetIds.CONTAINER_INSTALLATION_ALERTS]: {},
  [widgetIds.COORDINATE_NOT_REGISTER_ALERTS]: {},
  [widgetIds.VEHICLE_REMIND_ALERTS]: {},
  [widgetIds.ALL_COURSE_STATUS]: {},
  [widgetIds.RECENT_CONTRACT_START_LIST]: {},
  [widgetIds.RECENT_ADDED_ACCOUNTS]: {},
  [widgetIds.RECENT_ADDED_SPOT_COLLECTIONS]: {},
};

export const getDefaultWidgetConfiguration = (widgetId) => {
  if (!widgetId) return null;
  return defaultConfigurations[widgetId];
};
