import { useWindowHeight } from "@react-hook/window-size";
import { selector } from "ducks/Loading";
import { userSelector } from "ducks/User";
import {
  selector as drawerSelector,
  closeDrawer,
  openDrawer,
} from "ducks/Drawer";
import { useSelector, useDispatch } from "react-redux";
import useClasses from "utils/useClasses";
import useSize from "utils/useSize";
import { stylesCreator } from "./styles";

/**
 * 認証後のページテンプレートを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {JSX.Element} children 子コンポーネント
 * @returns {JSX.Element}
 */
export function Container({ render, children }) {
  const classes = useClasses(stylesCreator);
  const loading = useSelector(selector).status;
  const user = useSelector(userSelector);
  const open = useSelector(drawerSelector)?.isOpen;
  const dispatch = useDispatch();
  const { isMobileSize } = useSize();
  const screenHeight = useWindowHeight();

  const handleToggleMenu = () => {
    if (open) {
      dispatch(closeDrawer());
    } else {
      dispatch(openDrawer());
    }
  };

  return render({
    classes: classes,
    isDemo: process.env.REACT_APP_IS_DEMO === "true",
    children: children,
    open: open,
    onToggleMenu: handleToggleMenu,
    user: user,
    loading: loading,
    screenHeight: screenHeight,
    isMobileSize: isMobileSize,
  });
}
