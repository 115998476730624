import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LoadingSkeleton } from "views/pages/Dashboard/Components";
import { format } from "date-fns";

const columns = [
  {
    field: "termOfAgreementStartedOn",
    headerName: "契約期間開始日",
    width: 100,
    renderCell: (params) => {
      return format(
        new Date(params.row.termOfAgreementStartedOn),
        "yyyy-MM-dd"
      );
    },
  },
  {
    field: "name",
    headerName: "契約書名",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "parties A",
    headerName: "契約者（甲）",
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      return params.row.partyCompanies?.[0].name ?? "";
    },
  },
  {
    field: "parties B",
    headerName: "契約者（乙）",
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      return params.row.partyCompanies?.[1].name ?? "";
    },
  },
  {
    field: "type",
    headerName: "契約形態",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return params.row.type.name;
    },
  },
];

/**
 * Presententerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.loading - ローディング状態かどうか
 * @param {Array} props.data - 表示するデータの配列
 * @param {Array} props.columns - データグリッドの列設定
 * @param {Function} props.onClickCell - セルクリック時のコールバック関数
 * @param {Function} props.handleMoreData - もっと見るボタンのクリック時のコールバック関数
 * @param {boolean} props.navigating - ナビゲーション中かどうか
 */
export const Presententer = ({
  loading,
  data,
  name,
  handleMoreData,
  onClickCell,
  navigating,
}) => {
  if (loading) return <LoadingSkeleton loading size={300} />;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        px: 1.5,
        pt: 2,
      }}
    >
      <Typography
        sx={(theme) => ({
          height: theme.spacing(5),
        })}
        fontWeight="bold"
        textAlign="left"
        color="text.secondary"
      >
        {name}
      </Typography>
      <Box
        sx={(theme) => ({
          width: "100%",
          height: `calc(100% - ${theme.spacing(11)})`,
        })}
      >
        <DataGridPro
          loading={navigating}
          onCellClick={(params) => {
            onClickCell(params.row.id);
          }}
          rowHeight={40}
          columns={columns}
          rows={data}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "center",
          height: theme.spacing(5),
        })}
      >
        <Button
          onClick={handleMoreData}
          sx={{
            textAlign: "center",
            mt: 1,
          }}
        >
          もっと見る <ExpandMoreIcon />
        </Button>
      </Box>
    </Box>
  );
};
