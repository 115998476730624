import { RoleNames } from "./constants";

/**
 * 除外する権限名を取得します。
 * @param {string} roleName 権限名
 * @returns {array}
 */
export const fetchRemoveRoleNames = (roleName) => {
  switch (roleName) {
    // オーナー
    case "オーナー":
    case "管理者":
      return RoleNames.filter((name) => name === "オーナー");

    // 一般ユーザーはコントロールできない
    case "一般ユーザー":
      return RoleNames;

    // 異常値のため全て無効にする
    default:
      return RoleNames;
  }
};

/**
 * コントロール可能であるか確認します。
 * @param {object} currentUser 現在のユーザー
 * @param {object} user ユーザー
 * @returns {boolean}
 */
export const canControl = (currentUser, user) => {
  // 指定なしの場合は"できない"とみなす
  if (!currentUser || !user) {
    return false;
  }

  // 同一ユーザの場合は操作できない
  if (currentUser.id === user.id) {
    return false;
  }

  switch (currentUser?.roleName) {
    // オーナーは操作可能
    case "オーナー":
      return true;
    // 管理者はオーナー権限は操作できない
    case "管理者":
      return user?.roleName !== "オーナー";
    default:
      // 一般ユーザーと無効な権限は操作不可
      return false;
  }
};

export const canTransferOwnerRole = (currentUser, user) => {
  // 指定なしの場合は"できない"とみなす
  if (!currentUser || !user) {
    return false;
  }
  // 同一ユーザの場合は操作できない
  if (currentUser.id !== user.id) {
    return false;
  }
  // オーナーはオーナー権限を移行できる
  if (currentUser.roleName === "オーナー") {
    return true;
  }
  return false;
};
