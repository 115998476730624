import { useTheme } from "@mui/material";

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {boolean} [props.animate=false] - アラートボーダーをアニメーションするかどうか。
 * @param {import("@mui/material").AlertProps["variant"]} props.variant - アラートのバリアント。
 * @param {import("@mui/material").AlertProps["severity"]} [props.severity="warning"] - アラートの重大度。
 * @param {React.ReactNode} props.children - アラートの内容。"severity"]} [props.severity="warning"] - The severity of the alert.
 * @returns {JSX.Element} アニメーションされたアラートコンポーネント。
 */
export const Container = ({ render, ...props }) => {
  const theme = useTheme();
  return render({
    ...props,
    theme,
  });
};
