import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { API, graphqlOperation } from "utils/graphqlOperation";
import { searchCarryWorkJournalsByOffset } from "api/graphql/queries";
import { selector as userWidgetsSelector } from "ducks/Widgets";
import { debugLog } from "utils/log";

import { sampleData } from "./sample";

/**
 * コンテナコンポーネント
 *
 * @param {Object} props - プロパティ
 * @param {Function} props.render - レンダリング関数
 * @param {string} props.widgetId - ウィジェットID
 * @param {boolean} props.editMode - 編集モードフラグ
 *
 * @returns {JSX.Element} レンダリングされたコンポーネント
 *
 */
const TEMP_DEFAULT_VALUE_WHEN_ZERO = 0.01;
export const Container = ({ render, widgetId, editMode }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    widgets: { items: widgetsArray },
  } = useSelector(userWidgetsSelector);

  const widget = widgetsArray[widgetId];

  const load = useCallback(() => {
    setLoading(true);
    return API.graphql(
      graphqlOperation(searchCarryWorkJournalsByOffset, {
        filter: {
          date: { eq: format(new Date(), "yyyy-MM-dd") },
        },
      })
    )
      .then((res) => {
        const courses = res.data.searchCarryWorkJournalsByOffset?.items;
        setCourses(
          courses.map((course) => {
            const data = [
              {
                name: "Completed",
                value: course.taskDoneCount || TEMP_DEFAULT_VALUE_WHEN_ZERO,
              },
              {
                name: "Skipped",
                value: course.taskSkippedCount || TEMP_DEFAULT_VALUE_WHEN_ZERO,
              },
              {
                name: "Uncompleted",
                value:
                  course.taskTotal ||
                  0 - course.taskDoneCount ||
                  0 - course.taskSkippedCount ||
                  TEMP_DEFAULT_VALUE_WHEN_ZERO,
              },
            ];

            return {
              ...course,
              taskTotal: course.taskTotal || 0,
              data,
            };
          })
        );
      })
      .catch((err) => {
        debugLog("searchCarryWorkJournalsByOffset: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (editMode) {
      setCourses(sampleData);
    } else {
      load();
    }
  }, [editMode, load]);

  const onMouseEnter = (_, index) => {
    setActiveIndex(index);
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  return render({
    courses,
    onMouseEnter,
    activeIndex,
    loading,
    name: widget?.name,
    navigateTo,
  });
};
