import { useNavigate } from "react-router-dom";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/*
 * object shape
 *   order
 *   name
 *   scheduled
 *   wasteName
 *   quantity
 *   unit
 *   times
 *     start
 *     end
 */

/**
 * 回収場所を表示するコンポーネントです。
 * @param {object} value 回収場所情報
 * @returns
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return render({
    value: value,
    classes: classes,
    navigateTo: navigateTo,
    ...props,
  });
};
