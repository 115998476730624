export const sampleData = [
  {
    id: 1,
    name: "可燃ごみ",
    data: [
      {
        key: "Completed",
        value: 10,
      },
      {
        key: "Uncompleted",
        value: 20,
      },
      {
        key: "Skipped",
        value: 30,
      },
    ],
    taskTotal: 60,
    assignedVehicle: {
      number: "北九州１１の１２３４",
      modelOfVehicleName: "１０ｔダンプ",
    },
    assignedUsers: [
      {
        id: 1,
        name: "事務B",
      },
      {
        id: 2,
        name: "事務C",
      },
    ],
  },
  {
    id: 2,
    name: "不燃ごみ",
    data: [
      {
        key: "Completed",
        value: 20,
      },
      {
        key: "Uncompleted",
        value: 30,
      },
      {
        key: "Skipped",
        value: 40,
      },
    ],
    taskTotal: 90,
    assignedVehicle: {
      number: "北九州１１の１２３４",
      modelOfVehicleName: "１０ｔダンプ",
    },
    assignedUsers: [
      {
        id: 1,
        name: "事務B",
      },
      {
        id: 2,
        name: "事務C",
      },
    ],
  },
  {
    id: 3,
    name: "資源ごみ",
    data: [
      {
        key: "Completed",
        value: 30,
      },
      {
        key: "Uncompleted",
        value: 40,
      },
      {
        key: "Skipped",
        value: 50,
      },
    ],
    taskTotal: 120,
    assignedVehicle: {
      number: "北九州１１の１２３４",
      modelOfVehicleName: "１０ｔダンプ",
    },
    assignedUsers: [
      {
        id: 1,
        name: "事務B",
      },
      {
        id: 2,
        name: "事務C",
      },
    ],
  },
  {
    id: 4,
    name: "粗大ごみ",
    data: [
      {
        key: "Completed",
        value: 40,
      },
      {
        key: "Uncompleted",
        value: 50,
      },
      {
        key: "Skipped",
        value: 60,
      },
    ],
    taskTotal: 150,
    assignedVehicle: {
      number: "北九州１１の１２３４",
      modelOfVehicleName: "１０ｔダンプ",
    },
    assignedUsers: [
      {
        id: 1,
        name: "事務B",
      },
      {
        id: 2,
        name: "事務C",
      },
    ],
  },
  {
    id: 5,
    name: "有害ごみ",
    data: [
      {
        key: "Completed",
        value: 50,
      },
      {
        key: "Uncompleted",
        value: 60,
      },
      {
        key: "Skipped",
        value: 70,
      },
    ],
    taskTotal: 180,
    assignedVehicle: {
      number: "北九州１１の１２３４",
      modelOfVehicleName: "１０ｔダンプ",
    },
    assignedUsers: [
      {
        id: 1,
        name: "事務B",
      },
      {
        id: 2,
        name: "事務C",
      },
    ],
  },
];
