import { useEffect, useImperativeHandle } from "react";
import { debugLog } from "utils/log";
import { useForm } from "react-hook-form";
import { basicSchema } from "./Validations/basic";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";

const defaultValues = {
  mode: "daily",
  method: "sum",
  dataset: "wasteCollectionRecordActualValue",
  dateType: "wasteCollectionScheduleDate",
  aggregateOn: {
    start: DateTime.now().minus({ month: 1 }).startOf("month").toJSDate(),
    end: DateTime.now().toJSDate(),
  },
  groupColumns: {
    logisticsCourseName: false,
    wasteGeneratorCompanyName: true,
    wasteCollectionWorkplaceCode: false,
    wasteCollectionWorkplaceName: true,
    wasteCollectionWorkplacePrefecture: false,
    wasteCollectionWorkplaceCity: false,
    wasteLargeClassName: true,
    wasteMiddleClassName: false,
    wasteSmallClassName: false,
    wasteKindName: false,
    wasteName: false,
    wasteDisposalCompanyName: true,
    wasteDisposalWorkplaceName: false,
    wasteDisposalWorkplacePrefecture: false,
    wasteDisposalWorkplaceCity: false,
    wasteDisposalMethodLargeClassName: false,
    wasteDisposalMethodMiddleClassName: false,
    wasteDisposalMethodSmallClassName: false,
    wasteDisposalMethodDetails: false,
    wasteQuantityUnitName: true,
  },
};

/**
 * 集計条件の設定を行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = (data) => debugLog("集計リクエスト送信: ", data),
  onError = (data) => debugLog("集計リクエスト送信失敗:", data),
  datasetList = [],
  dateTypeList = [],
  methodList = [],
  ...props
}) => {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  const { trigger, getValues, reset, formState, control } = methods;

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
    });
  }, [reset, value]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  return render({
    control: control,
    datasetList: datasetList,
    dateTypeList: dateTypeList,
    methodList: methodList,
    trigger: trigger,
    ...props,
  });
};
