import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ローディングスケルトンを表示するためのコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.loading - ローディング状態
 * @param {string} props.bgcolor - 背景色
 */
export const LoadingSkeleton = containerPresententer(Container, Presententer);
