import React from "react";
import { FullScreenDialog, XGridComponent } from "views/molecules";

/**
 * グリッドコンポーネントを含むフルスクリーンダイアログをレンダリングします。
 *
 * @param {Object} props.dialog - ダイアログの状態オブジェクト。
 * @param {boolean} props.dialog.open - ダイアログが開いているかどうかを示します。
 * @param {Function} props.dialog.toggle - ダイアログの状態を切り替える関数。
 * @param {Object} props.xGridParams - XGridComponentのパラメータ。
 * @param {Function} props.onSelected - グリッドで選択が行われたときのコールバック関数。
 *
 * @returns {JSX.Element}
 */

export const Presententer = ({ dialog, xGridParams, onSelected }) => {
  return (
    <FullScreenDialog
      title="未配車の回収依頼一覧"
      textConfirm=""
      open={dialog.open}
      onClose={() => dialog.toggle(false)}
      onClickSubmit={() => {}}
      visibilityConfirm={false}
    >
      <XGridComponent
        onChangeSelection={onSelected}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={false}
        onFilter={false}
        onColumns={false}
        {...xGridParams}
        rows={xGridParams.rows.map((r, i) => ({ id: i, ...r }))}
      />
    </FullScreenDialog>
  );
};
