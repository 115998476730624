import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * @param {object} props - プロパティオブジェクト。
 * @param {boolean} [props.animate=false] - アラートボーダーをアニメーションするかどうか。
 * @returns {JSX.Element} アニメーションされたアラートコンポーネント。
 */

export const AlertBox = containerPresententer(Container, Presententer);
