import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * WeekCollectedChartsWidgetコンポーネント
 *
 * このコンポーネントは、収集量のグラフを表示します。
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうかを示すフラグ
 * @param {string} props.widgetId - ウィジェットID
 *
 * @returns {JSX.Element} 収集量のグラフを表示するコンポーネント
 */
export const WeekCollectedChartsWidget = containerPresententer(
  Container,
  Presententer
);
