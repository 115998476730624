import { createSlice } from "@reduxjs/toolkit";

const Drawer = createSlice({
  name: "drawer",
  initialState: {
    isOpen: true,
  },
  reducers: {
    openDrawer: (state) => {
      state.isOpen = true;
    },
    closeDrawer: (state) => {
      state.isOpen = false;
    },
  },
});

export const selector = (store) => store.Drawer;
// Export actions
export const { openDrawer, closeDrawer } = Drawer.actions;

// Export reducer
export default Drawer.reducer;
